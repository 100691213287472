<!--
 * @Author: wang peng
 * @Description: 直联电站新增
-->
<template>
  <ykc-form class="el-form-wrap directConnectionStation-detail">
    <!--温馨提示-->
    <ykc-warm-tip
      type="info"
      show-icon
      multi-line
      description="温馨提示：当前步骤配置基于通用监管补贴要求，请如实填写，以便影响补贴申报。"></ykc-warm-tip>
    <el-steps :active="active" align-center finish-status="success" v-if="!privatePile">
      <el-step title="基础信息" />
      <el-step title="监管信息" />
      <el-step title="合同信息" />
      <el-step title="运营信息" />
    </el-steps>
    <el-steps :active="active" align-center finish-status="success" v-else>
      <el-step title="基础信息" />
      <el-step title="监管信息" />
      <el-step title="运营信息" />
    </el-steps>
    <div>
      <!-- 基础信息 -->
      <ykc-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm0"
        class="el-form-wrap"
        v-show="active === 0">
        <ykc-form-item label="电站名称" prop="name">
          <ykc-input
            placeholder="请输入电站名称"
            maxlength="50"
            @change="handleChangeName"
            v-model="ruleForm.name"></ykc-input>
        </ykc-form-item>

        <ykc-form-item
          class="img-error"
          label="归属商户"
          prop="operatorId"
          bottomTip="温馨提示：归属商户变更后，统计、财务等信息均发生变化，请谨慎操作">
          <ykc-dropdown :data="OperatorListData" v-model="ruleForm.operatorId"></ykc-dropdown>
        </ykc-form-item>
        <ykc-form-item label="BD" prop="stationBd">
          <ykc-input placeholder="请输入BD" maxlength="50" v-model="ruleForm.stationBd"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="物业" prop="propertyId">
          <ykc-dropdown :data="propertyData" v-model="ruleForm.propertyId"></ykc-dropdown>
        </ykc-form-item>
        <ykc-form-item label="投资方" prop="investorId">
          <ykc-dropdown :data="investData" v-model="ruleForm.investorId"></ykc-dropdown>
        </ykc-form-item>
        <ykc-form-item label="场地名称" prop="siteId">
          <ykc-dropdown :data="siteData" v-model="ruleForm.siteId"></ykc-dropdown>
        </ykc-form-item>
        <ykc-form-item label="电站地址" prop="addressId">
          <ykc-cascader
            :isObject="true"
            :options="addressData"
            v-model="ruleForm.addressId"
            @change="changeDistrict"></ykc-cascader>
        </ykc-form-item>
        <ykc-form-item label="是否从益虫平台迁移" prop="isEvchongMigrate">
          <ykc-radio
            :data="[
              { id: 1, name: '是' },
              { id: 0, name: '否' },
            ]"
            v-model="ruleForm.isEvchongMigrate"></ykc-radio>
        </ykc-form-item>
        <ykc-form-item label="入驻时间" prop="enterTime">
          <ykc-date-picker
            type="date"
            format="yyyy-MM-dd"
            v-model="ruleForm.enterTime"></ykc-date-picker>
        </ykc-form-item>
        <ykc-form-item label="定位方式" prop="locType">
          <ykc-radio
            :data="[
              { id: '0', name: '地址定位' },
              { id: '1', name: '经纬度定位' },
            ]"
            @change="LocTypeChange"
            v-model="ruleForm.locType"></ykc-radio>
        </ykc-form-item>
        <ykc-form-item v-if="!Number(ruleForm.locType)" :label="'详细地址'" prop="detailAddress">
          <div class="flex">
            <ykc-input
              placeholder="请输入详细地址"
              maxlength="100"
              v-model="ruleForm.detailAddress"
              style="flex: 1; margin-right: 10px"></ykc-input>
            <ykc-button @click="clickAddress">地图定位</ykc-button>
          </div>
        </ykc-form-item>
        <ykc-form-item v-if="Number(ruleForm.locType)" label="经度" prop="longitude">
          <ykc-input
            placeholder="请输入经度"
            maxlength="100"
            v-model="ruleForm.longitude"></ykc-input>
        </ykc-form-item>
        <ykc-form-item v-if="Number(ruleForm.locType)" label="纬度" prop="latitude">
          <ykc-input
            placeholder="请输入纬度"
            maxlength="100"
            v-model="ruleForm.latitude"></ykc-input>

          <ykc-button @click="clickAddress" style="margin-top: 10px">地图定位</ykc-button>
        </ykc-form-item>
        <ykc-form-item>
          <div class="flex">
            <station-map
              ref="ykcMap"
              class="ykc-map"
              style="flex: 1"
              @mapClick="mapClick"></station-map>
          </div>
        </ykc-form-item>
      </ykc-form>
      <!-- 监管信息 -->
      <ykc-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm1"
        class="el-form-wrap"
        v-show="active === 1">
        <ykc-form-item label="电站位置" prop="locationAddress">
          <ykc-radio
            :data="[
              { id: 1, name: '地上电站' },
              { id: 2, name: '地下电站' },
            ]"
            v-model="ruleForm.locationAddress"></ykc-radio>
        </ykc-form-item>

        <ykc-form-item label="可用总电容" prop="capacity">
          <ykc-input maxlength="8" placeholder="请输入可用总电容" v-model="ruleForm.capacity">
            <template slot="append">KV·A</template>
          </ykc-input>
        </ykc-form-item>

        <ykc-form-item label="电站类型" prop="stationType">
          <ykc-dropdown :data="stationTypeData" v-model="ruleForm.stationType"></ykc-dropdown>
        </ykc-form-item>

        <ykc-form-item label="建设场所" prop="buildAddress">
          <ykc-dropdown
            :data="this.buildAddressData"
            v-model="ruleForm.buildAddress"></ykc-dropdown>
        </ykc-form-item>

        <ykc-form-item label="值守人员" prop="dutyCount">
          <ykc-input
            maxlength="8"
            placeholder="请输入值守人员"
            v-model="ruleForm.dutyCount"></ykc-input>
        </ykc-form-item>

        <ykc-form-item label="是否独立报装" prop="isAloneApply">
          <ykc-radio
            :data="[
              { id: 0, name: '否' },
              { id: 1, name: '是' },
            ]"
            @change="handleCompeteChange"
            v-model="ruleForm.isAloneApply"></ykc-radio>
        </ykc-form-item>

        <template v-if="ruleForm.isAloneApply === 1">
          <div
            v-for="(item, index) in ruleForm.aloneApplyInfoDTOS"
            :key="item.key"
            class="compete-wrap">
            <div class="header">
              <div class="compete-title">{{ `报装户号 ${index + 1}` }}</div>
              <div
                v-if="ruleForm.aloneApplyInfoDTOS && ruleForm.aloneApplyInfoDTOS.length > 1"
                class="compete-delete"
                @click="handleDeleteCompete(index)">
                <i class="el-icon-delete"></i>
              </div>
            </div>
            <div class="content">
              <ykc-form-item
                label="报装户号"
                :prop="`aloneApplyInfoDTOS.${index}.stationAccountNumber`"
                :rules="{
                  required: true,
                  message: '请输入报装户号',
                  trigger: 'blur',
                }">
                <ykc-input
                  placeholder="请输入报装户号"
                  v-model="item.stationAccountNumber"></ykc-input>
              </ykc-form-item>

              <ykc-form-item
                label="功率"
                :prop="`aloneApplyInfoDTOS.${index}.stationCapacity`"
                :rules="[
                  {
                    required: true,
                    message: '请输入功率',
                    trigger: 'blur',
                  },
                  {
                    trigger: 'blur',
                    pattern: regexpObj.regexp.input.decimalNumberType_fixed_2.regexp,
                    message:
                      regexpObj.regexp.input.decimalNumberType_fixed_2.errorTips.error('功率'),
                  },
                ]">
                <ykc-input type="number" placeholder="请输入功率" v-model="item.stationCapacity">
                  <template slot="append">KW</template>
                </ykc-input>
              </ykc-form-item>
            </div>
          </div>
          <div class="compete-add" @click="handleAddCompete">
            <i class="el-icon-plus add-btn"></i>
            <span>添加报装户号</span>
          </div>
        </template>

        <ykc-form-item label="是否公共停车场" prop="isPublicParkingLot">
          <ykc-radio
            :data="[
              { id: 0, name: '否' },
              { id: 1, name: '是' },
            ]"
            @change="handlePublicParkingLot"
            v-model="ruleForm.isPublicParkingLot"></ykc-radio>
        </ykc-form-item>
        <ykc-form-item
          v-if="ruleForm.isPublicParkingLot === 1"
          label="停车场库编号"
          prop="parkingLotNumber">
          <ykc-input
            placeholder="请输入停车场库编号"
            v-model="ruleForm.parkingLotNumber"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="是否为私桩社区" prop="isPrivatePile">
          <ykc-radio
            :data="[
              { id: 0, name: '否' },
              { id: 1, name: '是' },
            ]"
            v-model="ruleForm.isPrivatePile"
            :disabled="isEdit"
            @change="privatePileChange"></ykc-radio>
        </ykc-form-item>
      </ykc-form>
      <!-- 合同信息 -->
      <ykc-form
        :model="ruleForm"
        :rules="rules"
        :ref="privatePile ? '' : 'ruleForm2'"
        class="el-form-wrap"
        v-show="!privatePile && active === 2">
        <ykc-form-item label="合同日期区间" prop="contractDTO.contractTime">
          <ykc-date-picker
            format="yyyy-MM-dd"
            v-model="ruleForm.contractDTO.contractTime"
            @change="handleChangeContractTime"></ykc-date-picker>
        </ykc-form-item>

        <ykc-form-item label="结算日期区间" prop="contractDTO.settleTime">
          <ykc-date-picker
            format="yyyy-MM-dd"
            v-model="ruleForm.contractDTO.settleTime"></ykc-date-picker>
        </ykc-form-item>
        <ykc-form-item label="合同编号" prop="contractDTO.contractNo">
          <ykc-input
            placeholder="请输入合同编号"
            maxlength="50"
            v-model="ruleForm.contractDTO.contractNo"></ykc-input>
        </ykc-form-item>

        <ykc-form-item label="电能表名称" prop="contractDTO.eleTable">
          <ykc-input
            placeholder="请输入电能表名称"
            maxlength="50"
            v-model="ruleForm.contractDTO.eleTable"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="上传附件" prop="contractDTO.contractAttachment">
          <ykc-upload
            type="files"
            :size="1"
            ref="contractAttachment"
            :checkInfo="{ typeMess: '文件格式错误，请重新导入', sizeMess: '文件大小不超过' }"
            @handleChange="uploadFile"
            @handleRemove="handleRemove('contractAttachment')"></ykc-upload>
        </ykc-form-item>
        <!-- :file="appFile" -->
        <!-- @handleRemove="handleRemove" -->
        <!-- <ykc-form-item
          prop="img"
          label="电站图片"
          class="file-error"
          bottomTip="温馨提示：只能上传jpg/jpeg/png文件，且不超过1M">
          <div class="flex upload-img-box">
            <ykc-upload
              v-for="(item, index) in uploadData"
              :key="index"
              :text="item.name"
              :ref="`stationFile${item.id}`"
              @handleChange="uploadImg"
              @handleRemove="handleRemoveLogo"></ykc-upload>
          </div>
        </ykc-form-item> -->
        <ykc-form-item label="场站联系人" prop="contractDTO.stationContacts">
          <ykc-input
            placeholder="请输入场站联系人"
            maxlength="50"
            v-model="ruleForm.contractDTO.stationContacts"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="场站联系人手机号" prop="contractDTO.stationPhone">
          <ykc-input
            placeholder="请输入场站联系人手机号"
            maxlength="11"
            v-model="ruleForm.contractDTO.stationPhone"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="取电方式" prop="contractDTO.eleWay">
          <ykc-dropdown
            placeholder="请选择取电方式"
            :data="eleWayData"
            v-model="ruleForm.contractDTO.eleWay"></ykc-dropdown>
        </ykc-form-item>
        <ykc-form-item label="合同描述" prop="contractDTO.contactsDescription">
          <ykc-input
            maxlength="500"
            type="textarea"
            placeholder="请输入合同描述"
            v-model="ruleForm.contractDTO.contactsDescription"></ykc-input>
        </ykc-form-item>
      </ykc-form>
      <!-- 运营信息 -->
      <ykc-form
        :model="ruleForm"
        :rules="rules"
        :ref="'ruleForm' + maxIndex"
        class="el-form-wrap"
        v-show="active === maxIndex">
        <ykc-form-item label="场站辅助设备">
          <ykc-checkbox
            :data="dataBtn"
            :configSet="{
              label: 'label',
              value: 'value',
            }"
            v-model="ruleForm.selectCheckbox3"
            @change="checkboxChange"></ykc-checkbox>
        </ykc-form-item>

        <ykc-form-item label="停车收费模式" prop="parkFeeType">
          <ykc-dropdown :data="parkFeeTypeData" v-model="ruleForm.parkFeeType"></ykc-dropdown>
        </ykc-form-item>

        <ykc-form-item label="停车收费提示" prop="parkFeeTip">
          <ykc-input
            placeholder="请输入停车收费提示"
            maxlength="30"
            v-model="ruleForm.parkFeeTip"></ykc-input>
        </ykc-form-item>

        <ykc-form-item label="服务设置">
          <ykc-dropdown
            :multiple="true"
            :multipleLimit="4"
            :data="this.stationLabelListData"
            v-model="ruleForm.stationLabelList"></ykc-dropdown>
        </ykc-form-item>
        <ykc-form-item label="结算方式" prop="settleWay">
          <ykc-dropdown :data="this.settleWayData" v-model="ruleForm.settleWay"></ykc-dropdown>
        </ykc-form-item>
        <ykc-form-item label="站点模式" prop="stationMode">
          <ykc-dropdown :data="this.stationModeData" v-model="ruleForm.stationMode"></ykc-dropdown>
        </ykc-form-item>
        <ykc-form-item label="推送平台">
          <ykc-dropdown
            :multiple="true"
            :data="this.supervisePlatData"
            v-model="ruleForm.configIds"></ykc-dropdown>
        </ykc-form-item>
        <ykc-form-item label="充电类型" prop="chargingType">
          <ykc-dropdown
            :data="this.chargingTypeData"
            v-model="ruleForm.chargingType"></ykc-dropdown>
        </ykc-form-item>
        <ykc-form-item label="基础设置" prop="baseFacilities">
          <ykc-dropdown
            :multiple="true"
            :multipleLimit="4"
            :data="this.baseFacilitiesData"
            v-model="ruleForm.baseFacilities"></ykc-dropdown>
        </ykc-form-item>
        <ykc-form-item label="站点功能设置" prop="stationFunSet">
          <ykc-dropdown
            :multiple="true"
            :multipleLimit="4"
            :data="this.stationFunSetData"
            v-model="ruleForm.stationFunSet"></ykc-dropdown>
        </ykc-form-item>
        <ykc-form-item label="备案编号">
          <ykc-input
            placeholder="请输入备案编号"
            maxlength="30"
            v-model="ruleForm.filingsNo"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="营业时间">
          <electrovalence-time-range
            ref="range"
            style="padding-top: 10px"
            v-model="ruleForm.rangeValue"
            :maxCount="1"
            :clearable="true"
            :selectedStatus.sync="selectedStatus"
            :groups="[{ value: 'active', label: 'active', background: 'pink' }]">
            <template #table="{ data }">
              <span style="font-size: 10px">
                选定当前营业时间区间: {{ data[0]?.start || '--' }}~{{ data[0]?.end || '--' }}
              </span>
            </template>
          </electrovalence-time-range>
        </ykc-form-item>

        <ykc-form-item
          prop="img"
          label="电站图片"
          class="file-error"
          bottomTip="温馨提示：只能上传jpg/jpeg/png文件，且不超过1M">
          <div class="flex upload-img-box">
            <ykc-upload
              v-for="(item, index) in uploadData"
              :key="index"
              :text="item.name"
              :ref="`stationFile${item.id}`"
              @handleChange="uploadImg"
              @handleRemove="handleRemoveLogo"></ykc-upload>
          </div>
        </ykc-form-item>
        <ykc-form-item label="备注">
          <ykc-input
            maxlength="500"
            type="textarea"
            placeholder="请输入备注"
            v-model="ruleForm.remark"></ykc-input>
        </ykc-form-item>
      </ykc-form>
    </div>
  </ykc-form>
</template>
<script>
  import dayjs from 'dayjs';
  import regexpObj from '@/utils/regexp';
  import {
    directPage,
    stationPage,
    treeUtils,
    uploadUtils,
    propertymentData,
    investmentData,
    propertySiteData,
    cipherUnione,
  } from '@/service/apis';
  import { loadDicItem } from '@/utils/dictionary';
  import StationMap from './StationMap';
  import ElectrovalenceTimeRange from '@/components/electrovalence-time-range.vue';

  export default {
    name: 'AddOrEdit',
    components: {
      StationMap,
      ElectrovalenceTimeRange,
    },
    props: {
      id: {
        type: Number,
        default: 0,
      },
      active: {
        type: Number,
        default: 0,
      },
      drawerType: {
        type: String,
        default: 'add',
      },
    },
    data() {
      return {
        regexpObj,
        privatePile: false, // 是否是私桩社区
        maxIndex: 3,
        activeId: '',
        isEdit: false,
        addressName: '', // 省市区名称
        selectedStatus: 'active',
        detail: {}, // 详情数据
        addressData: [], // 城市数据源
        OperatorListData: [], // 归属商户数据源
        propertyData: [], // 物业数据源
        investData: [], // 投资方数据源
        siteData: [], // 场地数据源
        dataBtn: [
          {
            value: 1,
            label: '道闸',
          },
          {
            value: 2,
            label: '地锁',
          },
        ],
        // 电站类型原数据
        stationTypeData: [
          { id: 1, name: '公共' },
          { id: 50, name: '个人' },
          { id: 100, name: '公共(专用)' },
          { id: 101, name: '环卫(专用)' },
          { id: 102, name: '物流(专用)' },
          { id: 103, name: '出租车(专用)' },
          { id: 255, name: '其他' },
        ],
        // 建设场站原数据
        buildAddressData: [
          { id: '1', name: '居民区' },
          { id: '2', name: '公共机构' },
          { id: '3', name: '企事业单位' },
          { id: '4', name: '写字楼' },
          { id: '5', name: '工业园区' },
          { id: '6', name: '交通枢纽' },
          { id: '7', name: '大型文体设施' },
          { id: '8', name: '城市绿地' },
          { id: '9', name: '大型建筑配建停车场' },
          { id: '10', name: '路边停车位' },
          { id: '11', name: '城际高速服务区' },
          { id: '255', name: '其他' },
        ],
        // 停车收费模式原数据
        parkFeeTypeData: [
          { id: 1, name: '免费停车' },
          { id: 2, name: '收费停车' },
          { id: 3, name: '充电限免' },
        ],
        // 站点标签原数据
        stationLabelListData: [
          { id: '1', name: '免费WIFI' },
          { id: '2', name: '空调休息室' },
          { id: '3', name: '按摩室' },
          { id: '4', name: '便利店' },
          { id: '5', name: '洗车' },
          { id: '6', name: '饮用水' },
          { id: '7', name: '厕所' },
          { id: '8', name: '快餐' },
          { id: '9', name: '自动售货机' },
          { id: '10', name: '雨棚' },
        ],
        // 取电方式
        eleWayData: [
          { id: 1, name: '物业取电' },
          { id: 2, name: '电力报装' },
        ],
        // 结算方式
        settleWayData: [
          { id: 1, name: '按系统' },
          { id: 2, name: '按抄表' },
        ],
        // 站点功能设置
        stationFunSetData: [],
        // 基础设置
        baseFacilitiesData: [],
        // 充电类型
        chargingTypeData: [
          { id: 1, name: '慢充' },
          { id: 2, name: '快充' },
          { id: 3, name: '快慢混合' },
        ],
        // 推送平台
        supervisePlatData: [],

        // 站点模式
        stationModeData: [
          { id: 1, name: '独立单桩' },
          { id: 2, name: '一拖N' },
          { id: 3, name: '混合模式' },
          { id: 4, name: '储能快充' },
        ],
        uploadData: [
          { id: 1, name: '主入口图' },
          { id: 2, name: '标志路径' },
          { id: 3, name: '电站全景' },
          { id: 4, name: '电桩特写' },
          { id: 5, name: '其他图片' },
        ],
        ruleForm: {
          name: '', // 电站名称
          operatorId: 4, // 归属商户
          addressId: [], // 省市区
          provinceId: '',
          cityId: '',
          districtId: '',
          detailAddress: '',
          longitude: null, // 经度
          latitude: null, // 纬度
          streetCode: null, // 街道
          streetName: null, // 街道名
          stationBd: null,
          locationAddress: 1,
          isEvchongMigrate: 0,
          enterTime: dayjs().format('YYYY-MM-DD'),
          capacity: null,
          stationType: 1,
          buildAddress: '',
          dutyCount: 0,
          isAloneApply: 0,
          aloneApplyInfoDTOS: [],
          isPublicParkingLot: 0,
          parkingLotNumber: '',
          isPrivatePile: 0,
          parkingLockFlag: 0,
          isBarrierGate: 0,
          parkFeeType: 2,
          parkFeeTip: '以物业公示为准',
          stationFunSet: ['1', '4'],
          rangeValue: [
            {
              type: 'active',
              start: '00:00',
              end: '24:00',
            },
          ], // 时间选择区间
          selectCheckbox3: [],
          stationLabelList: [],
          stationImageList: [],
          stationOperateTimeList: [],
          remark: '',
          locType: '0', // 定位方式
          propertyId: '',
          investorId: '',
          siteId: '',
          settleWay: 1,
          chargingType: 1,
          contractDTO: {
            contactsDescription: '',
            contractAttachment: '',
            contractTime: [],
            settleTime: [],
            attachmentName: '',
            contractEndTime: '',
            contractNo: '',
            contractStartTime: '',
            createdTime: '',
            eleTable: '',
            eleWay: '',
            settleEndTime: '',
            settleStartTime: '',
            stationContacts: '',
            stationPhone: '',
          },
        },
        rules: {
          name: [
            { required: true, message: '请输入电站名称', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.nameType.regexp,
              message: regexpObj.regexp.input.nameType.errorTips.error('电站名称'),
            },
          ],
          operatorId: [{ required: true, message: '请选择归属商户', trigger: 'blur' }],
          isEvchongMigrate: [
            { required: true, message: '请选择是否从益虫平台迁移', trigger: 'blur' },
          ],
          enterTime: [{ required: true, message: '请选择入驻时间', trigger: 'change' }],
          propertyId: [{ required: true, message: '请选择物业', trigger: 'blur' }],
          investorId: [{ required: true, message: '请选择投资方', trigger: 'blur' }],
          siteId: [{ required: true, message: '请选择场地', trigger: 'blur' }],
          addressId: [{ required: true, message: '请选择省市区', trigger: 'blur' }],
          detailAddress: [
            { required: true, message: '请输入详细地址', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.addressType.regexp,
              message: regexpObj.regexp.input.addressType.errorTips.error('详细地址'),
            },
          ],
          locationAddress: [{ required: true, message: '请选择电站位置', trigger: 'blur' }],
          capacity: [
            { required: true, message: '请输入可用总电容', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.integerNumberType.regexp,
              message: regexpObj.regexp.input.integerNumberType.errorTips.error(
                '可用总电容',
                99999999
              ),
            },
          ],
          stationType: [{ required: true, message: '请选择电站类型', trigger: 'blur' }],
          buildAddress: [{ required: true, message: '请选择建设场所', trigger: 'blur' }],
          dutyCount: [
            { required: true, message: '请输入值守人员', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.zeroIntegerNumberType.regexp,
              message: regexpObj.regexp.input.zeroIntegerNumberType.errorTips.error(
                '值守人员',
                99999999
              ),
            },
          ],
          isAloneApply: [{ required: true, message: '请选择是否独立报装', trigger: 'blur' }],
          isPublicParkingLot: [
            { required: true, message: '请选择是否公共停车场', trigger: 'blur' },
          ],
          parkingLotNumber: [{ required: true, message: '请输入停车场库编号', trigger: 'blur' }],
          parkFeeType: [{ required: true, message: '请选择停车收费模式', trigger: 'blur' }],
          parkFeeTip: [
            { required: false, message: '请输入停车收费提示', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.inlineTextType.regexp,
              message: regexpObj.regexp.input.inlineTextType.errorTips.error('停车收费提示'),
            },
          ],
          longitude: [
            {
              required: true,
              message: '请输入经度',
              trigger: 'blur',
            },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.validateLongitudeLongitude.regexp,
              message: regexpObj.regexp.input.validateLongitudeLongitude.errorTips.error('经度'),
            },
          ],
          latitude: [
            {
              required: true,
              message: '请输入纬度',
              trigger: 'blur',
            },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.validateLatitude.regexp,
              message: regexpObj.regexp.input.validateLatitude.errorTips.error('纬度'),
            },
          ],
          settleWay: [{ required: true, message: '请选择结算方式', trigger: 'blur' }],
          stationMode: [{ required: true, message: '请选择站点模式', trigger: 'blur' }],
          chargingType: [{ required: true, message: '请选择充电类型', trigger: 'blur' }],
          baseFacilities: [{ required: false, message: '请选择基础设置', trigger: 'blur' }],
          stationFunSet: [{ required: true, message: '请选择站点功能设置', trigger: 'blur' }],
          contractDTO: {
            contractTime: [{ required: true, message: '请选择合同日期区间', trigger: 'change' }],
            settleTime: [{ required: false, message: '请选择结算日期区间', trigger: 'change' }],
            contractNo: [{ required: false, message: '请输入合同编号', trigger: 'blur' }],
            contractAttachment: [{ required: false, message: '请上传合同附件', trigger: 'change' }],
            stationContacts: [{ required: true, message: '请输入场站联系人', trigger: 'blur' }],
            stationPhone: [
              { required: true, message: '请输入场站联系人手机号', trigger: 'blur' },
              {
                trigger: 'blur',
                pattern: regexpObj.regexp.input.cellphoneType.regexp,
                message: regexpObj.regexp.input.cellphoneType.errorTips.error('手机号'),
              },
            ],
            eleTable: [{ required: false, message: '请输入电能表名称', trigger: 'blur' }],
            eleWay: [{ required: true, message: '请选择取电方式', trigger: 'blur' }],
            contactsDescription: [{ required: false, message: '请输入合同描述', trigger: 'blur' }],
          },

          isPrivatePile: [{ required: true, message: '请选择是否为私桩社区', trigger: 'blur' }],
        },
        longitude: null, // 经度
        latitude: null, // 纬度
        streetCode: null, // 街道
        streetName: null, // 街道名
      };
    },
    created() {
      this.baseFacilitiesData = loadDicItem('base_facilities');
      this.stationFunSetData = loadDicItem('station_fun_set');
      // this.supervisePlatData = loadDicItem('supervise_plat');
      // 获取机构下拉列表/获取树,新增获取
      this.requestGetDistrict();
      this.requestPlatData();
      this.requestOperatorList();
      this.requestPropertyList();
      this.requestInvestList();
      this.requestSiteList();
      this.$emit('changeMaxIndex', this.maxIndex);
      // 编辑回显
      if (this.id) {
        this.isEdit = true;
        stationPage
          .dirDetail({ stationId: this.id })
          .then(res => {
            console.log('res.isPrivatePile', res.isPrivatePile);
            if (res.isPrivatePile === 0) {
              this.privatePile = false;
              this.ruleForm = {
                ...this.ruleForm,
                ...res,
              };
              if (this.drawerType === 'copy') {
                delete this.ruleForm.stationId;
              }
              if (res.contractDTO) {
                this.ruleForm.contractDTO = {
                  ...res.contractDTO,
                  contractTime: [
                    res.contractDTO.contractStartTime,
                    res.contractDTO.contractEndTime,
                  ],
                  settleTime: [res.contractDTO.settleStartTime, res.contractDTO.settleEndTime],
                };
                if (res.contractDTO.contractAttachment) {
                  this.$refs.contractAttachment.fileList = [
                    {
                      name: res.contractDTO.attachmentName,
                      url: res.contractDTO.contractAttachment,
                    },
                  ];
                }
              } else {
                this.ruleForm.contractDTO = {
                  contactsDescription: '',
                  contractAttachment: '',
                  contractTime: [],
                  settleTime: [],
                  attachmentName: '',
                  contractEndTime: '',
                  contractNo: '',
                  contractStartTime: '',
                  createdTime: '',
                  eleTable: '',
                  eleWay: '',
                  settleEndTime: '',
                  settleStartTime: '',
                  stationContacts: '',
                  stationPhone: '',
                };
              }
            } else {
              this.privatePile = true;
              delete res.contractDTO;
              this.ruleForm = {
                ...this.ruleForm,
                ...res,
              };
              console.log('this.ruleForm', this.ruleForm);
            }
            this.maxIndex = this.privatePile ? 2 : 3;
            this.$emit('changeMaxIndex', this.maxIndex);
            // 电站地址
            this.ruleForm.addressId = [
              res.area[0].id || null,
              res.area[1].id || null,
              res.area[2].id || null,
            ];

            this.addressName = res.area[0].name + res.area[1].name + res.area[2].name;

            // 设置定位
            if (this.$refs.ykcMap) {
              // this.$refs.ykcMap.setLngLat(this.ruleForm.longitude, this.ruleForm.latitude);
              this.$refs.ykcMap.addMarker(this.ruleForm.longitude, this.ruleForm.latitude);

              this.longitude = this.ruleForm.longitude;
              this.latitude = this.ruleForm.latitude;
            }

            // 道闸选取
            if (this.ruleForm.isBarrierGate === 1) {
              this.ruleForm.selectCheckbox3.push(1);
            }
            this.ruleForm.configIds = [];
            // 推送平台
            if (this.ruleForm.extNameVOS) {
              this.ruleForm.extNameVOS.forEach(item => {
                this.ruleForm.configIds.push(item.configId);
              });
            }

            if (this.ruleForm.parkingLockFlag === 1) {
              this.ruleForm.selectCheckbox3.push(2);
            }

            //  标签数据格式转换
            if (this.ruleForm.stationLabelList?.length > 0) {
              this.ruleForm.stationLabelList = res.stationLabelList.map(String);
            }

            // 电站时间选取
            this.ruleForm.rangeValue = [
              {
                type: 'active',
                start: res.operateTimeVOList[0].startPeriod,
                end: res.operateTimeVOList[0].endPeriod,
              },
            ];
            console.log('this.ruleForm.rangeValue', this.ruleForm.rangeValue);
            this.selectedStatus = 'active';

            // 电站图片回显
            if (res.picsVOList && res.picsVOList.length !== 0) {
              res.picsVOList.forEach(item => {
                this.$nextTick(() => {
                  this.$refs[`stationFile${item.locationIndex}`][0].fileList = [{ url: item.url }];
                  this.ruleForm.stationImageList.push({
                    url: item.url,
                    locationIndex: item.locationIndex,
                  });
                });
              });
            }
            this.$nextTick(() => {
              this.clickAddress();
            });
          })
          .catch(() => {});
      }
    },
    computed: {
      formData() {
        const formData = {
          name: this.ruleForm.name,
          operatorId: this.ruleForm.operatorId,
          provinceId: this.ruleForm.provinceId,
          cityId: this.ruleForm.cityId,
          districtId: this.ruleForm.districtId,
          detailAddress: this.ruleForm.detailAddress,
          longitude: this.ruleForm.longitude,
          stationBd: this.ruleForm.stationBd,
          isEvchongMigrate: this.ruleForm.isEvchongMigrate,
          enterTime: this.ruleForm.enterTime,
          latitude: this.ruleForm.latitude,
          streetCode: this.ruleForm.streetCode,
          streetName: this.ruleForm.streetName,
          locationAddress: this.ruleForm.locationAddress,
          capacity: this.ruleForm.capacity,
          stationType: this.ruleForm.stationType,
          buildAddress: this.ruleForm.buildAddress,
          dutyCount: this.ruleForm.dutyCount,
          isAloneApply: this.ruleForm.isAloneApply,
          aloneApplyInfoDTOS: this.ruleForm.aloneApplyInfoDTOS,
          isPublicParkingLot: this.ruleForm.isPublicParkingLot,
          parkingLotNumber: this.ruleForm.parkingLotNumber,
          parkingLockFlag: this.ruleForm.parkingLockFlag,
          isBarrierGate: this.ruleForm.isBarrierGate,
          parkFeeType: this.ruleForm.parkFeeType,
          parkFeeTip: this.ruleForm.parkFeeTip,
          stationLabelList: this.ruleForm.stationLabelList,

          stationOperateTimeList:
            this.ruleForm.rangeValue.length !== 0
              ? [
                  {
                    startPeriod: this.ruleForm.rangeValue[0]?.start,
                    endPeriod: this.ruleForm.rangeValue[0]?.end,
                  },
                ]
              : [], // 营业时间
          stationImageList: this.ruleForm.stationImageList, // 电站图片
          remark: this.ruleForm.remark,
          isPrivatePile: this.ruleForm.isPrivatePile,
          chargingType: this.ruleForm.chargingType,
          baseFacilities: this.ruleForm.baseFacilities,
          settleWay: this.ruleForm.settleWay,
          stationFunSet: this.ruleForm.stationFunSet,
          configIds: this.ruleForm.configIds,
          stationMode: this.ruleForm.stationMode,
          filingsNo: this.ruleForm.filingsNo,
          propertyId: this.ruleForm.propertyId,
          investorId: this.ruleForm.investorId,
          siteId: this.ruleForm.siteId,
        };
        if (!this.privatePile) {
          formData.contractDTO = {
            contactsDescription: this.ruleForm.contractDTO.contactsDescription,
            contractAttachment: this.ruleForm.contractDTO.contractAttachment,
            attachmentName: this.ruleForm.contractDTO.attachmentName,
            contractEndTime: this.ruleForm.contractDTO.contractTime[1],
            contractNo: this.ruleForm.contractDTO.contractNo,
            contractStartTime: this.ruleForm.contractDTO.contractTime[0],
            createdTime: this.ruleForm.contractDTO.createdTime,
            eleTable: this.ruleForm.contractDTO.eleTable,
            eleWay: this.ruleForm.contractDTO.eleWay,
            settleEndTime: this.ruleForm.contractDTO.settleTime[1],
            settleStartTime: this.ruleForm.contractDTO.settleTime[0],
            stationContacts: this.ruleForm.contractDTO.stationContacts,
            stationPhone: this.ruleForm.contractDTO.stationPhone,
          };
        }
        if (this.id) {
          formData.stationId = this.id;
        }

        return formData;
      },
    },
    methods: {
      privatePileChange(e) {
        this.privatePile = !!e;
        this.maxIndex = this.privatePile ? 2 : 3;

        this.$emit('changeMaxIndex', this.maxIndex);
      },
      /**
       * 电站change方法调用信息提示
       */
      handleChangeName() {
        this.ruleForm.contractDTO.contractNo = this.ruleForm.name;
        this.ruleForm.contractDTO.eleTable = this.ruleForm.name;
        this.ruleForm.contractDTO.contactsDescription = this.ruleForm.name;
      },
      /**
       * 合同时间change方法调用信息提示
       */
      handleChangeContractTime() {
        if (!this.ruleForm.contractDTO.settleTime.length) {
          this.ruleForm.contractDTO.settleTime = [...this.ruleForm.contractDTO.contractTime];
        }
      },
      /**
       * 场站辅助设备选取change方法调用信息提示
       */
      checkboxChange(e) {
        if (e.includes(1)) {
          this.ruleForm.isBarrierGate = 1;
        } else {
          this.ruleForm.isBarrierGate = 0;
        }

        if (e.includes(2)) {
          this.ruleForm.parkingLockFlag = 1;
        } else {
          this.ruleForm.parkingLockFlag = 0;
        }
      },
      /**
       * 获取地图需要的城市名
       */
      changeDistrict(city) {
        const arr1 = [];
        city.forEach(item => {
          arr1.push(item.name);
        });
        this.addressName = arr1.join('');
        this.ruleForm.provinceId = city[0].id;
        this.ruleForm.cityId = city[1].id;
        this.ruleForm.districtId = city[2].id;
      },
      /**
       * 地图定位按钮
       */
      clickAddress() {
        // 判断省市区
        this.$refs.ruleForm0.validateField('addressId', errId => {
          if (Number(this.ruleForm.locType)) {
            // 经纬度地图定位
            this.$refs.ruleForm0.validateField('latitude', errAdd => {
              console.log(this.ruleForm);
              if (!errId && !errAdd) {
                this.$nextTick(() => {
                  console.log('++++++++++经纬度地图定位++++++++++++');
                  this.$refs.ykcMap.addMarker(this.ruleForm.longitude, this.ruleForm.latitude);
                  this.ruleForm.detailAddress = this.$refs.ykcMap.address;
                  this.longitude = this.ruleForm.longitude;
                  this.latitude = this.ruleForm.latitude;
                  console.log(this.ruleForm.longitude, this.ruleForm.latitude);
                  console.log(this.longitude, this.latitude);
                });
              }
            });
          } else {
            // 判断详细地址
            this.$refs.ruleForm0.validateField('detailAddress', errAdd => {
              if (!errId && !errAdd) {
                // if (this.id) {
                //   this.$refs.ykcMap.setMarker(); // 设置坐标  编辑第一次点击定位按钮，不定位
                // } else {
                console.log(this.addressName, this.ruleForm.detailAddress);
                this.$nextTick(() => {
                  this.$refs.ykcMap.parseAddress(
                    this.addressName,
                    this.ruleForm.detailAddress,
                    obj => {
                      console.log('++++++++++详细地址定位++++++++++++');
                      this.ruleForm.longitude = obj.lng; // 经度
                      this.ruleForm.latitude = obj.lat; // 纬度
                      this.longitude = obj.lng;
                      this.latitude = obj.lat;
                      console.log(this.ruleForm.longitude, this.ruleForm.latitude);
                      console.log(this.longitude, this.latitude);
                    }
                  );
                });
                // }
              }
            });
          }
        });
      },
      /**
       * 手动校中点位
       */
      mapClick(lngLat) {
        console.log('lngLat==', lngLat);
        // 经纬度输入定位时关闭回显数据
        if (Number(this.ruleForm.locType)) {
          this.ruleForm.longitude = lngLat.lng;
          this.ruleForm.latitude = lngLat.lat;
          this.ruleForm.streetCode = lngLat.towncode;
          this.ruleForm.streetName = lngLat.township;
        }

        // 存储一下当前的选中位置
        this.longitude = lngLat.lng;
        this.latitude = lngLat.lat;
        this.streetCode = lngLat.towncode;
        this.streetName = lngLat.township;
      },
      /**
       * @desc  图片上传
       */
      uploadImg(file, fileList, isCheck, txt) {
        let locationIndex = null;
        switch (txt) {
          case '主入口图':
            locationIndex = 1;
            break;
          case '标志路径':
            locationIndex = 2;
            break;
          case '电站全景':
            locationIndex = 3;
            break;
          case '电桩特写':
            locationIndex = 4;
            break;
          case '其他图片':
            locationIndex = 5;
            break;
          default:
            break;
        }
        if (isCheck) {
          const formData = new FormData();
          const param = {
            file: file.raw,
            bizName: '',
          };
          // 处理入参格式
          Object.entries(param).forEach(([k, v]) => {
            formData.append(k, v);
          });
          uploadUtils.uploadImg(formData).then(res => {
            this.ruleForm.stationImageList.push({
              url: res,
              locationIndex,
            });
          });
        }
      },
      /**
       * @desc 图片删除
       * */
      handleRemoveLogo(file) {
        this.ruleForm.stationImageList.splice(
          this.ruleForm.stationImageList.findIndex(item => item.url === file.url),
          1
        );
      },
      /**
       * @desc 表单提交事件，用于父组件定义的"完成"或"保存"按钮点击事件
       * */
      submitForm() {
        return new Promise((resolve, reject) => {
          // 判断是否手动调整过地图位置，且重新赋值经纬度
          if (this.latitude) {
            this.formData.longitude = this.longitude;
            this.formData.latitude = this.latitude;
            this.formData.detailAddress = this.$refs.ykcMap.address;
          }
          this.validateForm()
            .then(() => {
              if (!this.id || this.drawerType === 'copy') {
                delete this.formData.stationId;
                stationPage
                  .dirAdd(this.formData)
                  .then(res => {
                    console.log('res++++新增', res);
                    this.$message({
                      message: '保存成功',
                      type: 'success',
                    });
                    resolve();
                  })
                  .catch(() => {});
              } else {
                stationPage
                  .dirUpdate(this.formData)
                  .then(res => {
                    console.log('res++++编辑', res);
                    this.$message({
                      message: '保存成功',
                      type: 'success',
                    });
                    resolve();
                  })
                  .catch(() => {});
              }
            })
            .catch(err => {
              console.log(err);
              reject();
            });
        });
      },
      /**
       * @desc 校验当前表格是否通过
       * */
      validateForm() {
        return new Promise((resolve, reject) => {
          this.$refs[`ruleForm${this.active}`].validate(valid => {
            if (valid) {
              if (this.longitude !== null) {
                resolve();
              } else {
                this.$message({
                  message: '请先点击地图定位按钮获取当前坐标!',
                  type: 'warning',
                });
              }
            } else {
              reject();
            }
          });
        });
      },
      /**
       * @desc 校验全部表格是否通过
       * */
      validateAllForm() {
        console.log(Object.keys(this.$refs));
        return new Promise((resolve, reject) => {
          Promise.all(
            Object.keys(this.$refs)
              .filter(o => o.includes('ruleForm'))
              .map(o => {
                return new Promise((_resolve, _reject) => {
                  console.log(this.$refs[o]);
                  this.$refs[o].validate(valid => {
                    if (valid) {
                      _resolve();
                    } else {
                      _reject();
                    }
                  });
                });
              })
          )
            .then(() => {
              resolve();
            })
            .catch(err => {
              console.log('err++++', err);
              reject();
            });
        });
      },
      /**
       * 获取归属商户列表
       */
      requestOperatorList() {
        directPage
          .userOperatorList({ operatorType: '1' })
          .then(res => {
            const params = res;
            params.map(item => {
              return this.OperatorListData.push({
                id: item.operatorId,
                name: item.name,
              });
            });
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 获取物业列表
       */
      requestPropertyList() {
        propertymentData
          .propertymentList({
            current: 1,
            size: 99999,
          })
          .then(res => {
            console.log('res', res);
            const params = res.records;
            params.map(item => {
              return this.propertyData.push({
                id: item.id,
                name: item.propertyName,
              });
            });
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 获取投资方列表
       */
      requestInvestList() {
        investmentData
          .investmentList({
            current: 1,
            size: 99999,
          })
          .then(res => {
            console.log('res', res);
            const params = res.records;
            params.map(item => {
              return this.investData.push({
                id: item.id,
                name: item.investorName,
              });
            });
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 获取站点列表
       */
      requestSiteList() {
        propertySiteData.getList().then(res => {
          res.map(item => {
            return this.siteData.push({
              id: item.id,
              name: item.siteName,
            });
          });
        });
      },
      /**
       * 获取推送平台数据
       */
      requestPlatData() {
        cipherUnione
          .list({
            current: 0,
            size: 99999,
          })
          .then(res => {
            const params = res.records;
            params.map(item => {
              return this.supervisePlatData.push({
                id: item.id,
                name: item.extName,
              });
            });
          });
      },
      /**
       * 获取区域数据
       */
      requestGetDistrict() {
        treeUtils
          .getDistrict({})
          .then(res => {
            const params = res.districts;
            this.addressData = params;
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 切换定位方式
       */
      LocTypeChange() {
        if (!this.id) {
          this.ruleForm.detailAddress = null;
          this.ruleForm.longitude = null;
          this.ruleForm.latitude = null;
        }
        if (this.ruleForm.locType === '0') {
          this.ruleForm.detailAddress = this.$refs.ykcMap.address || '';
        }
      },

      // 删除文件
      handleRemove(propName) {
        this.ruleForm.contractDTO[propName] = '';
      },
      /** 导入文件 */
      uploadFile(file, fileList, isCheck) {
        console.log(file, fileList, isCheck);
        if (isCheck) {
          const formData = new FormData();
          const param = {
            file: file.raw,
            bizName: '',
          };
          console.log('param', param);
          // 处理入参格式
          Object.entries(param).forEach(([k, v]) => {
            formData.append(k, v);
          });
          uploadUtils.uploadFile(formData).then(res => {
            this.ruleForm.contractDTO.contractAttachment = res;
            this.ruleForm.contractDTO.attachmentName = file.name;
            this.$refs.contractAttachment.fileList = [
              {
                name: file.name,
                url: res,
              },
            ];
            this.$refs.ruleForm2.validateField('contractDTO.contractAttachment');
          });
        }
      },
      // 新增报装户号
      handleAddCompete() {
        this.ruleForm.aloneApplyInfoDTOS.push({
          stationAccountNumber: '',
          stationCapacity: undefined,
          key: Date.now(),
        });
      },
      // 删除报装户号
      handleDeleteCompete(index) {
        this.ruleForm.aloneApplyInfoDTOS.splice(index, 1);
      },
      // 是否独立报装
      handleCompeteChange(value) {
        if (value === 1) {
          // 独立报装
          this.ruleForm.aloneApplyInfoDTOS = [
            {
              stationAccountNumber: '',
              stationCapacity: undefined,
              key: Date.now(),
            },
          ];
        } else {
          this.ruleForm.aloneApplyInfoDTOS = [];
        }
      },
      // 是否公共停车场
      handlePublicParkingLot() {
        this.ruleForm.parkingLotNumber = '';
      },
    },
  };
</script>
<style lang="scss" scoped>
  .directConnectionStation-detail {
    .upload-img-box {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    .el-steps {
      margin: 20px 0;
    }
    .compete-wrap {
      border: 1px solid #dcdfe6;
      border-radius: 5px;
      margin-bottom: 10px;
      .header {
        height: 38px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #dcdfe6;
        padding: 0 10px;
        align-items: center;
        .compete-title {
          font-size: 12px;
          color: #000;
        }
        .compete-delete {
          cursor: pointer;
          font-size: 12px;
        }
      }
      .content {
        padding: 20px;
      }
    }
    .compete-add {
      height: 32px;
      border: 1px dashed rgb(217, 217, 217);
      color: #4d8cfd;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-bottom: 10px;
      .add-btn {
        font-size: 12px;
        color: #4d8cfd;
        margin-right: 4px;
      }
    }
    ::v-deep .el-step__head.is-success {
      color: rgb(52, 158, 250);
      border-color: rgb(52, 158, 250);
    }
    ::v-deep .el-step__title.is-success {
      font-weight: bold;
      color: rgb(52, 158, 250);
    }
    ::v-deep .el-step__description.is-success {
      color: rgb(52, 158, 250);
    }
  }
</style>
