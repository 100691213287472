<!--
 * @Author: wanghui@ykccn.com
 * @Date: 2023-02-13
 * @Description: 营销管理/平台营销工具/卡券价格活动/新增或者编辑(注册领券)
-->
<template>
  <ykc-form :model="ruleForm" :rules="rules" ref="ruleForm">
    <ykc-form-item label="活动名称" prop="activityName">
      <ykc-input
        v-model="ruleForm.activityName"
        placeholder="请输入活动名称"
        maxlength="50"></ykc-input>
    </ykc-form-item>
    <ykc-form-item label="对外名称" prop="outName">
      <ykc-input v-model="ruleForm.outName" maxlength="50" placeholder="请输入对外名称"></ykc-input>
    </ykc-form-item>
    <ykc-form-item label="活动时间" prop="activityTime">
      <ykc-date-picker limitDate="limitBefore" v-model="ruleForm.activityTime"></ykc-date-picker>
    </ykc-form-item>
    <ykc-form-item label="活动说明" prop="activityRemark">
      <ykc-input
        v-model="ruleForm.activityRemark"
        type="textarea"
        placeholder="请输入活动说明"
        maxlength="500"></ykc-input>
    </ykc-form-item>
    <ykc-form-item label="卡券设置" class="required">
      <ykc-combination-item
        required
        :basicData="basicData"
        :selectData="coupons"
        :data="setData"
        :maxlength="20"
        addText="添加卡券"
        ref="combination"></ykc-combination-item>
    </ykc-form-item>
    <ykc-form-item label="活动范围" prop="activityPosition">
      <ykc-radio
        :data="[
          { id: '0', name: '城市' },
          { id: '1', name: '全国' },
        ]"
        v-model="ruleForm.activityPosition"></ykc-radio>
    </ykc-form-item>
    <ykc-form-item prop="stationCityList" label="选择电站" v-if="ruleForm.activityPosition == '0'">
      <ykc-tree
        ref="stationCityTree"
        :data="stationCityData"
        :props="stationProps"
        @check-change="handleStationCityTree"></ykc-tree>
    </ykc-form-item>
  </ykc-form>
</template>
<script>
  import regexpObj from '@/utils/regexp';
  import { couponActivity, marketManage, treeUtils } from '@/service/apis';

  export default {
    name: 'JoinCouponCollectionAdd',
    props: {
      editModel: {
        type: String,
        default: 'add', // add edit copy
      },
      activityId: {
        type: String,
      },
    },
    data() {
      return {
        selectData: [],
        stationCityData: [],
        stationProps: {
          label: 'name',
          children: 'subNodes',
          disabled: 'disabled',
        },
        coupons: [], // 卡券数据
        setData: [], // 组合框初始化数据
        // 展示的项目
        basicData: [
          {
            comName: 'YkcDropdown',
            key: 'couponId',
            remote: true,
            value: '',
            placeholder: '请选择优惠券/抵用卡',
            rules: { required: true, message: '请选择优惠券/抵用卡', trigger: 'blur' },
            prepend: '卡券',
            only: true,
            data: [],
          },
          {
            comName: 'YkcInput',
            value: '',
            key: 'issueCount',
            maxlength: 8,
            prepend: '数量',
            append: '张/人',
            placeholder: '请输入发送数量',
            rules: [
              { required: true, message: '请输入发送数量', trigger: 'blur' },
              {
                pattern: regexpObj.regexp.input.integerNumberType.regexp,
                message: regexpObj.regexp.input.integerNumberType.errorTips.error(
                  '卡券数量',
                  99999999
                ),
                trigger: 'blur',
              },
              // { validator: this.checkCouponNumber, trigger: 'blur' },
            ],
          },
        ],
        ruleForm: {
          activityName: '',
          outName: '',
          activityRemark: '',
          activityTime: [],
          activityPosition: '0',
        },
        rules: {
          activityName: [
            { required: true, message: '请输入活动名称', trigger: 'blur' },
            {
              pattern: regexpObj.regexp.input.nameType.regexp,
              message: regexpObj.regexp.input.nameType.errorTips.error('活动名称'),
              trigger: 'blur',
            },
            { validator: this.onlyCheckName, trigger: 'blur' },
          ],
          outName: [{ required: true, message: '请输入对外名称', trigger: 'blur' }],
          activityTime: [{ required: true, trigger: 'change', message: '请选择活动时间' }],
          activityRemark: [
            { required: false, trigger: 'blur', message: '请输入活动说明' },
            {
              pattern: regexpObj.regexp.input.textAreaType.regexp,
              message: regexpObj.regexp.input.textAreaType.errorTips.error('活动说明'),
              trigger: 'blur',
            },
          ],
          stationCityList: [{ required: true, message: '请选择电站', trigger: 'change' }],
        },
      };
    },
    created() {
      this.$nextTick(() => {
        this.getData();
      });
    },
    methods: {
      getData() {
        const requests = [];
        const requestCoupons = marketManage.activityList({
          // cardCouponType://卡券类型（1:抵用卡，2:优惠券）
          useType: '1', // 使用类型（1:普通，2:会员）
          undertakerType: '1', // 承担方类型（1:平台，2:商户）
          // undertakerId://承担商户id
          // batchNumber;//批次号
        });
        const userTreeCityRequest = treeUtils.findCityStationTree({});
        requests.push(requestCoupons);
        requests.push(userTreeCityRequest);
        if (this.activityId && (this.editModel === 'edit' || this.editModel === 'copy')) {
          const requestDetail = couponActivity.registerActivityDetail({
            activityId: this.activityId,
          });
          requests.push(requestDetail);
        }
        Promise.all(requests).then(([resCoupon, stationCityData, resDetail]) => {
          if (this.activityId) {
            this.detail = resDetail || {};
          }
          if (resCoupon) {
            this.coupons = [];
            if (
              this.activityId &&
              (this.detail.activityStatus === '1' || this.detail.activityStatus === '2')
            ) {
              // 只有未开始进行中，进行复制卡券
              this.detail.rewardList.forEach(item => {
                this.coupons.push({
                  cardCouponId: item.rewardId,
                  id: item.rewardId,
                  name: item.rewardName,
                  cardCouponType: item.rewardType,
                  leftCount: item.rewardCount,
                });
              });
            }
            const coupons = resCoupon.map(item => {
              // key: 'cardCouponId',
              // name: 'cardCouponName',
              const { cardCouponId, cardCouponName, remainderNum } = item;
              item.id = cardCouponId;
              item.name = cardCouponName;
              item.leftCount = remainderNum;
              return item;
            });
            this.coupons.push(...coupons);
            if (this.activityId) {
              this.$nextTick(() => {
                this.setData = this.formatSetData();
              });
            }
          }
          if (resDetail) {
            this.detail = resDetail || {};
            this.fillInitData();
          }
          this.formatStationCityTreeData(stationCityData || []);
        });
      },
      handleActivityPosition() {},
      handleStationTree() {
        //  this.ruleForm.stationList = checkedKeys.length > 0 ? checkedKeys : '';
        this.ruleForm.stationList = this.$refs.stationTree.getCheckedKeys(true) || [];
      },
      formatStationCityTreeData(originData) {
        // 转换省市区为data-picker数据
        function transPCDItem(item) {
          const subNodes = [];
          if (item.subTree) {
            item.subTree.forEach(itm => {
              subNodes.push(transPCDItem(itm));
            });
          }
          const obj = {
            id: item.id,
            name: item.name || '未知',
            subNodes,
            disabled: false,
          };
          return obj;
        }

        function transPCD(data) {
          if (!data || !data.length) {
            return [];
          }
          const result = [];
          data.forEach(item => {
            result.push(transPCDItem(item));
          });
          return result;
        }

        this.stationCityData = transPCD(originData);
      },
      /**
       * 初始化form数据
       */
      fillInitData() {
        if (this.editModel === 'edit') {
          this.ruleForm.activityName = this.detail.activityName;
          this.ruleForm.outName = this.detail.outName;
          this.ruleForm.activityTime = [];
          if (this.detail.activityBeginTime) {
            this.ruleForm.activityTime.push(this.detail.activityBeginTime);
          }
          if (this.detail.activityEndTime) {
            this.ruleForm.activityTime.push(this.detail.activityEndTime);
          }
        }
        this.ruleForm.activityRemark = this.detail.activityRemark || '';
        this.setData = this.formatSetData();
      },
      formatSetData() {
        const selectedCoupons = [];
        this.detail.rewardList.forEach(item => {
          selectedCoupons.push({
            couponId: item.rewardId,
            // name: item.rewardName, // rewardCount
            issueCount: item.rewardCount,
          });
        });
        return selectedCoupons;
      },
      submitForm() {
        return new Promise(resolve => {
          const couponValidForm = this.$refs.combination.getResult();
          const dataValidForm = this.validateAllForm();
          Promise.all([couponValidForm, dataValidForm]).then(([couponResult]) => {
            // 新增/复制/编辑
            if (couponResult.validState) {
              const submitData = this.formData();
              submitData.rewardList = this.formatCouponData(couponResult.data || []);
              couponActivity.registerActivitySave(submitData).then(res => {
                console.log(res);
                this.$message({
                  message: '保存成功',
                  type: 'success',
                });
                resolve();
              });
            }
          });
        });
      },
      formatCouponData(selectedCoupons) {
        const couponDatas = [];
        if (selectedCoupons.length > 0) {
          selectedCoupons.forEach(selectItem => {
            const selectId = selectItem.couponId;
            for (let i = 0; i < this.coupons.length; i++) {
              const originCoupon = this.coupons[i];
              if (originCoupon.cardCouponId === selectId) {
                couponDatas.push({
                  rewardType: originCoupon.cardCouponType,
                  rewardId: selectId,
                  rewardCount: selectItem.issueCount || '',
                });
                break;
              }
            }
          });
        }
        return couponDatas;
      },
      formData() {
        const startTime =
          this.ruleForm.activityTime[0].indexOf('00:00:00') > -1
            ? this.ruleForm.activityTime[0]
            : `${this.ruleForm.activityTime[0]} 00:00:00`;
        const endTime =
          this.ruleForm.activityTime[1].indexOf('00:00:00') > -1
            ? this.ruleForm.activityTime[1]
            : `${this.ruleForm.activityTime[1]} 00:00:00`;
        const formData = {
          activityType: '1',
          activityInitiator: '1',
          activityInitiatorId: '0',
          activityName: this.ruleForm.activityName,
          outName: this.ruleForm.outName,
          activityBeginTime: startTime,
          activityEndTime: endTime,
          activityRemark: this.ruleForm.activityRemark || '',
          //  rewardList: this.ruleForm.rewardList,
        };
        // 编辑/copy
        if (this.editModel === 'edit') {
          formData.activityId = this.activityId;
        }
        return formData;
      },
      /**
       * 输入框数量校验
       */
      checkCouponNumber(rule, value, callback) {
        // 输入的所有数据
        const result = this.$refs.combination.dealResult();
        // 当前行index
        const rowIndex = rule.field.split('.')[1];
        // 选择的优惠券id
        const { cardCouponId = '' } = result[rowIndex];

        if (!cardCouponId) {
          return callback();
        }
        const [coupon] = this.coupons.filter(item => item.id === cardCouponId);
        return Number(value) > coupon.leftCount
          ? callback(`超过剩余数量${coupon.leftCount}张，请重新输入`)
          : callback();
      },
      handleStationCityTree() {
        //  this.ruleForm.stationList = checkedKeys.length > 0 ? checkedKeys : '';
        this.ruleForm.stationCityList = this.$refs.stationCityTree.getCheckedKeys(true) || [];
      },
      /**
       * 校验表单
       * @returns {Promise<unknown>}
       */
      validateAllForm() {
        return new Promise((resolve, reject) => {
          Promise.all(
            Object.keys(this.$refs)
              .filter(o => o.includes('ruleForm'))
              .map(o => {
                return new Promise((_resolve, _reject) => {
                  this.$refs[o].validate(valid => {
                    if (valid) {
                      _resolve();
                    } else {
                      _reject();
                    }
                  });
                });
              })
          )
            .then(() => {
              resolve();
            })
            .catch(err => {
              console.log(err);
              reject();
            });
        });
      },
      /**
       * 活动名称校验
       */
      onlyCheckName(rule, value, callback) {
        if (value) {
          couponActivity
            .checkNameOnly({
              activityName: value,
              activityType: '1',
              activityId: this.editModel === 'edit' ? this.activityId : '',
            })
            .then(res => {
              if (res?.repeatFlag) {
                // 重复
                // 校验未通过
                this.nameValidError = '活动名称已存在'; // res.resultDesc;
                callback(new Error(this.nameValidError));
                // eslint-disable-next-line no-dupe-else-if
              } else if (res?.repeatFlag === false) {
                // 校验通过
                this.nameValidError = '';
                callback();
              }
            });
        }
      },
    },
  };
</script>
<style lang="scss">
  .has-time.ykc-date-picker,
  .has-time.hourHide {
    .el-picker-panel__footer .el-button.el-button--text {
      display: none;
    }
  }
</style>
