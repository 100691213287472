<template>
  <scroll-layout class="feedbackdetails">
    <template slot="header">
      <div v-if="pageButtons.length > 0" class="button-wrap">
        <template v-for="(button, index) in pageButtons">
          <ykc-button
            :key="index"
            v-if="button.enabled"
            v-bind="button"
            @click="button.handleClick(button, $event)">
            {{ button.text }}
          </ykc-button>
        </template>
      </div>
    </template>
    <!-- <Reply :id="evaluateId" /> -->
    <ykc-detail class="detail-box">
      <div slot="cvBody">
        <div class="form-detail flex">
          <ykc-form :label-position="'left'" label-width="68px" class="list-left">
            <ykc-detail-item :label="'反馈单号：'">
              <span>{{ dealData(detailData.id) }}</span>
            </ykc-detail-item>
            <ykc-detail-item :label="'反馈时间：'">
              <span>{{ dealData(detailData.createdTime) }}</span>
            </ykc-detail-item>

            <ykc-detail-item :label="'电站名称：'">
              <span>{{ dealData(detailData.stationName) }}</span>
            </ykc-detail-item>
            <ykc-detail-item :label="'用户账号：'">
              <span>{{ dealData(detailData.userAccountNo) }}</span>
            </ykc-detail-item>
          </ykc-form>
        </div>
      </div>
    </ykc-detail>
    <ykc-detail>
      <div slot="cvBody">
        <div class="form-detail flex">
          <ykc-form class="list-left">
            <ykc-detail-item :label="'反馈类型：'">
              <span>{{ dealData(detailData.feedbackTypeName) }}</span>
            </ykc-detail-item>
            <ykc-detail-item :label="'反馈内容：'">
              <span>
                {{ dealData(detailData.feedbackDesc) }}
              </span>
            </ykc-detail-item>
            <ykc-detail-item :label="'业务单号：'">
              <span>
                {{ dealData(detailData.tradeNo) }}
              </span>
            </ykc-detail-item>
            <ykc-detail-item :label="'问题图片：'">
              <viewer :images="detailData.imageList">
                <img
                  style="width: 100px"
                  v-for="(src, index) in detailData.imageList"
                  :src="src"
                  :key="index" />
              </viewer>
            </ykc-detail-item>
          </ykc-form>
        </div>
      </div>
    </ykc-detail>
    <ykc-detail v-if="detailData.feedbackStatus === 1">
      <div slot="cvBody">
        <div class="form-detail flex">
          <ykc-form class="list-left">
            <ykc-detail-item :label="'处理意见：'">
              <span>{{ dealData(detailData.dealDesc) }}</span>
            </ykc-detail-item>
            <ykc-detail-item :label="'处理人：'">
              <span>{{ dealData(detailData.dealerName) }}</span>
            </ykc-detail-item>
            <ykc-detail-item :label="'处理时间：'">
              <span>{{ dealData(detailData.dealTime) }}</span>
            </ykc-detail-item>
          </ykc-form>
        </div>
      </div>
    </ykc-detail>
  </scroll-layout>
</template>

<script>
  import { omManagementApi } from '@/service/apis';

  export default {
    data() {
      return {
        id: '',
        detailData: {},
      };
    },
    created() {
      this.id = this.$route.query.id;
      this.getOrderDetail();
    },
    computed: {
      pageButtons() {
        return [
          {
            text: '返回',
            type: 'plain',
            enabled: true,
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ];
      },
    },
    methods: {
      getOrderDetail() {
        omManagementApi
          .feedbackDetail({
            id: this.id,
          })
          .then(res => {
            this.detailData = res;
          })
          .catch(err => {
            console.log(err);
          });
      },
      dealData(value) {
        if (value !== undefined && value !== null && value !== '') {
          return value;
        }
        return '——';
      },
    },
  };
</script>

<style lang="scss" scoped>
  .feedbackdetails {
    ::v-deep .cardview-head {
      display: none;
    }
  }
</style>
