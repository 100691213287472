<!--
 * @Author: wanghui@ykccn.com
 * @Date: 2023-02-14
 * @Description: 营销管理/平台营销工具/卡券价格活动/新增或者编辑(充值领券)
-->
<template>
  <ykc-form :model="ruleForm" :rules="rules" ref="ruleForm">
    <ykc-form-item label="活动名称" prop="activityName">
      <ykc-input
        v-model="ruleForm.activityName"
        maxlength="50"
        placeholder="请输入活动名称"></ykc-input>
    </ykc-form-item>
    <ykc-form-item label="对外名称" prop="outName">
      <ykc-input v-model="ruleForm.outName" maxlength="50" placeholder="请输入对外名称"></ykc-input>
    </ykc-form-item>
    <ykc-form-item label="活动时间" prop="activityTime">
      <ykc-date-picker limitDate="limitBefore" v-model="ruleForm.activityTime"></ykc-date-picker>
    </ykc-form-item>
    <ykc-form-item
      class="file-error img-error"
      label="活动入口图片"
      bottomTip="温馨提示：请上传1M以内尺寸为343px*64px的图片，用于客户端展示"
      prop="activityRule">
      <ykc-upload
        :size="1"
        ref="ykcupload"
        :file="setFile"
        @handleRemove="handleRemove"
        :checkInfo="{ typeMess: '文件格式错误，请重新导入', sizeMess: '文件大小不超过' }"
        @handleChange="handleUpload"></ykc-upload>
    </ykc-form-item>
    <ykc-form-item label="活动说明" prop="activityRemark">
      <ykc-input
        type="textarea"
        v-model="ruleForm.activityRemark"
        :maxlength="500"
        placeholder="请输入活动说明"></ykc-input>
    </ykc-form-item>
    <ykc-form-item label="卡券设置" class="required">
      <money-card-count v-model="couponData" :totalCardData="coupons" ref="moneyCard" />
    </ykc-form-item>
    <div class="limited-wrap">
      <ykc-form-item label="限制次数" prop="limitCount">
        <ykc-input
          v-model="ruleForm.limitCount"
          :maxlength="8"
          placeholder="请输入次数"></ykc-input>
      </ykc-form-item>
      <ykc-dropdown
        placeholder="请选择"
        :clearable="false"
        :data="LIMITED_TYPE"
        v-model="ruleForm.limitType"></ykc-dropdown>
    </div>
    <ykc-form-item prop="scopeType" label="可用维度" required>
      <ykc-radio
        :data="availableList"
        v-model="ruleForm.scopeType"
        @change="changeAvailable"></ykc-radio>
    </ykc-form-item>
    <ykc-form-item prop="stationIdList" label="选择电站" v-if="ruleForm.scopeType !== '4'">
      <ykc-tree
        v-model="checkAll"
        ref="operaTree"
        :data="treeData"
        :props="treeProps"
        :nodeKey="treeNodeKey"
        @check-change="handleTreeCheckChange"></ykc-tree>
    </ykc-form-item>
  </ykc-form>
</template>
<script>
  import MoneyCardCount from '@/components/dynamic-compose/money-card-count';
  import {
    couponActivity,
    marketManage,
    uploadUtils,
    treeUtils,
    stationLabelApi,
  } from '@/service/apis';
  import regexpObj from '@/utils/regexp';

  export default {
    components: { MoneyCardCount },
    props: {
      editModel: {
        type: String,
        default: 'add', // add edit copy
      },
      activityId: {
        type: String,
      },
    },
    data() {
      return {
        titel: '新增扫码发券',
        availableList: [
          {
            id: '1',
            name: '按城市选择',
          },
          {
            id: '2',
            name: '按商户选择',
          },
          {
            id: '3',
            name: '按电站分组',
          },
          {
            id: '4',
            name: '全部电站',
          },
        ],
        LIMITED_TYPE: [
          {
            id: '1',
            name: '次/人/天',
          },
          {
            id: '2',
            name: '次/人/活动周期',
          },
        ],
        // 车辆绑定初始数据
        selectData: [],
        couponData: [
          {
            money: '',
            cardCount: [
              {
                card: '',
                count: '',
              },
            ],
          },
        ], // 卡券数据
        basicData: [
          {
            comName: 'YkcDropdown',
            key: 'couponId',
            remote: true,
            value: '',
            placeholder: '请选择优惠券',
            rules: { required: true, message: '请选择优惠券', trigger: 'blur' },
            prepend: '优惠券',
            only: true,
            data: [],
          },
          {
            comName: 'YkcInput',
            value: '',
            key: 'issueCount',
            maxlength: 8,
            prepend: '数量',
            append: '张/人',
            placeholder: '请输入优惠券数量',
            rules: [
              { required: true, message: '请输入优惠券数量', trigger: 'blur' },
              {
                pattern: regexpObj.regexp.input.integerNumberType.regexp,
                message: regexpObj.regexp.input.integerNumberType.errorTips.error(
                  '卡券数量',
                  99999999
                ),
                trigger: 'blur',
              },
              { validator: this.checkCouponNumber, trigger: 'blur' },
            ],
          },
        ],
        checkAll: 'true',
        ruleForm: {
          activityName: '',
          outName: '',
          activityTime: [],
          limitType: '1',
          limitCount: '',
          activityRemark: '',
          activityRule: '',
          scopeType: '1',
          stationIdList: [],
        },
        rules: {
          activityName: [
            { required: true, message: '请输入活动名称', trigger: 'blur' },
            {
              pattern: regexpObj.regexp.input.nameType.regexp,
              message: regexpObj.regexp.input.nameType.errorTips.error('活动名称'),
              trigger: 'blur',
            },
            { validator: this.onlyCheckName, trigger: 'blur' },
          ],
          outName: [{ required: true, message: '请输入对外名称', trigger: 'blur' }],
          activityTime: [{ required: true, trigger: 'change', message: '请选择活动时间' }],
          activityInitiator: [{ required: true, trigger: 'change', message: '请选择活动发起方' }],
          activityOperatorId: [{ required: true, trigger: 'change', message: '请选择运营商' }],
          limitCount: [
            { required: true, trigger: 'blur', message: '请输入设置次数' },
            {
              pattern: regexpObj.regexp.input.integerNumberType.regexp,
              message: regexpObj.regexp.input.integerNumberType.errorTips.error(
                '设置次数',
                99999999
              ),
              trigger: 'blur',
            },
          ],
          activityRemark: [
            // 活动规则
            { required: false, trigger: 'blur', message: '请输入活动规则' },
            {
              pattern: regexpObj.regexp.input.textAreaType.regexp,
              message: regexpObj.regexp.input.textAreaType.errorTips.error('活动规则'),
              trigger: 'blur',
            },
          ],
          stationIdList: [{ required: true, message: '请选择电站', trigger: 'change' }],
        },
        userInfo: {},
        fleetOrg: [],
        treeCityData: [],
        treeCityProps: {
          label: 'name',
          children: 'subTree',
          disabled: 'disabled',
        },
        treeCityNodeKey: 'id',
        treeOperatorData: [],
        treeOperatorProps: {
          label: 'stationName',
          children: 'stationTreeVOList',
          disabled: 'disabled',
        },
        treeOperatorNodeKey: 'stationId',
        treeStationData: [],
        treeStationProps: {
          label: 'name',
          children: 'childList',
          disabled: 'disabled',
        },
        treeStationNodeKey: 'id',
        coupons: [],
        setData: [],
        setFile: [],
        limitedType: [], // dictionary.LIMITED_TYPE,
      };
    },
    computed: {
      treeData() {
        if (this.ruleForm.scopeType === '1') {
          return this.treeCityData;
        }
        if (this.ruleForm.scopeType === '2') {
          return this.treeOperatorData;
        }
        if (this.ruleForm.scopeType === '3') {
          return this.treeStationData;
        }
        return [];
      },
      treeProps() {
        if (this.ruleForm.scopeType === '1') {
          return this.treeCityProps;
        }
        if (this.ruleForm.scopeType === '2') {
          return this.treeOperatorProps;
        }
        if (this.ruleForm.scopeType === '3') {
          return this.treeStationProps;
        }
        return {
          label: 'name',
          children: 'subTree',
          disabled: 'disabled',
        };
      },
      treeNodeKey() {
        if (this.ruleForm.scopeType === '1') {
          return this.treeCityNodeKey;
        }
        if (this.ruleForm.scopeType === '2') {
          return this.treeOperatorNodeKey;
        }
        if (this.ruleForm.scopeType === '3') {
          return this.treeStationNodeKey;
        }
        return 'id';
      },
    },
    created() {
      this.getCouponAndTree();
    },
    model: {
      prop: 'drawerVisible',
      event: 'change',
    },
    methods: {
      getData() {
        const requests = [];
        const requestCoupons = marketManage.activityList({
          useType: '1', // 使用类型（1:普通，2:会员）
          undertakerType: '1', // 承担方类型（1:平台，2:商户）
        });
        requests.push(requestCoupons);
        if (this.activityId && (this.editModel === 'edit' || this.editModel === 'copy')) {
          const requestDetail = couponActivity.rechargeActivityDetail({
            activityId: this.activityId,
          });
          requests.push(requestDetail);
        }
        Promise.all(requests).then(([resCoupon, resDetail]) => {
          if (resDetail) {
            this.detail = resDetail || {};
          }
          if (resCoupon) {
            this.coupons = [];
            if (
              this.activityId &&
              (this.detail.activityStatus === '1' || this.detail.activityStatus === '2')
            ) {
              // 只有未开始进行中，进行复制卡券
              const cachedIds = []; // 去重id
              this.detail.rewardList.forEach(item => {
                if (cachedIds.indexOf(item.rewardId) < 0) {
                  this.coupons.push({
                    id: item.rewardId,
                    name: item.rewardName,
                    cardCouponType: item.rewardType,
                    //  leftCount: item.rewardCount,
                  });
                  cachedIds.push(item.rewardId);
                }
              });
            }
            const coupons = resCoupon.map(item => {
              const { cardCouponId, cardCouponName } = item;
              item.id = cardCouponId;
              item.name = cardCouponName;
              return item;
            });
            this.coupons.push(...coupons);
          }
          if (this.activityId) {
            this.fillInitData();
          }
        });
      },
      /**
       * 初始化form数据
       */
      fillInitData() {
        if (this.editModel === 'edit') {
          this.ruleForm.activityName = this.detail.activityName;
          this.ruleForm.outName = this.detail.outName;
          this.ruleForm.activityTime = [];
          if (this.detail.activityBeginTime) {
            this.ruleForm.activityTime.push(this.detail.activityBeginTime);
          }
          if (this.detail.activityEndTime) {
            this.ruleForm.activityTime.push(this.detail.activityEndTime);
          }
        }
        this.ruleForm.limitType = this.detail.limitType;
        this.ruleForm.limitCount = this.detail.limitCount;
        this.ruleForm.activityRemark = this.detail.activityRemark || '';
        this.$refs.ykcupload.fileList = [{ url: this.ruleForm.activityRule }];
        this.ruleForm.activityRule = this.detail.activityRule;
        this.setFile = this.detail.activityRule ? [{ url: this.detail.activityRule }] : [];
        this.couponData = this.formatSelectedData();
        this.ruleForm.scopeType = this.detail.scopeType;
        this.ruleForm.stationIdList = this.detail.stationIdList || [];
        this.$nextTick(() => {
          this.$refs.operaTree.setCheckedKeys(this.detail.stationIdList || [], true);
        });
      },
      formatSelectedData() {
        const selectedCoupons = [];
        const thresholds = [];
        this.detail.rewardList.forEach(item => {
          if (thresholds.indexOf(item.rewardThreshold) < 0) {
            thresholds.push(item.rewardThreshold);
          }
        });
        for (let i = 0; i < thresholds.length; i++) {
          const threshold = thresholds[i];
          const cardCount = [];
          this.detail.rewardList.forEach(coupon => {
            if (coupon.rewardThreshold === threshold) {
              // 找到该梯度
              cardCount.push({
                card: coupon.rewardId,
                count: coupon.rewardCount,
              });
            }
          });
          selectedCoupons.push({
            money: threshold,
            cardCount,
          });
        }
        /*
          couponData: [
            {
              money: '',
              cardCount: [
                {
                  card: '',
                  count: '',
                },
              ],
            },
          ] */

        return selectedCoupons;
      },
      submitForm() {
        return new Promise(resolve => {
          const couponValidForm = this.$refs.moneyCard.validate();
          const dataValidForm = this.validateAllForm();
          Promise.all([couponValidForm, dataValidForm]).then(([couponResult]) => {
            // 新增/复制/编辑
            if (couponResult) {
              const submitData = this.formData();
              submitData.rewardList = this.formatCouponData();
              couponActivity.rechargeActivitySave(submitData).then(res => {
                console.log(res);
                this.$message({
                  message: '保存成功',
                  type: 'success',
                });
                resolve();
              });
            }
          });
        });
      },
      validateAllForm() {
        return new Promise((resolve, reject) => {
          Promise.all(
            Object.keys(this.$refs)
              .filter(o => o.includes('ruleForm'))
              .map(o => {
                return new Promise((_resolve, _reject) => {
                  this.$refs[o].validate(valid => {
                    if (valid) {
                      _resolve();
                    } else {
                      _reject();
                    }
                  });
                });
              })
          )
            .then(() => {
              resolve();
            })
            .catch(err => {
              console.log(err);
              reject();
            });
        });
      },
      formatCouponData() {
        const rewards = [];
        this.couponData.forEach(item => {
          const { cardCount } = item;
          if (cardCount && cardCount.length > 0) {
            cardCount.forEach(item1 => {
              console.log(item1);
              const reward = { rewardThreshold: item.money };
              reward.rewardId = item1.card;
              reward.rewardCount = item1.count;
              for (let i = 0; i < this.coupons.length; i++) {
                if (this.coupons[i].id === item1.card) {
                  reward.rewardType = this.coupons[i].cardCouponType;
                  break;
                }
              }
              rewards.push(reward);
            });
          }
        });
        return rewards;
      },
      formData() {
        const startTime =
          this.ruleForm.activityTime[0].indexOf('00:00:00') > -1
            ? this.ruleForm.activityTime[0]
            : `${this.ruleForm.activityTime[0]} 00:00:00`;
        const endTime =
          this.ruleForm.activityTime[1].indexOf('00:00:00') > -1
            ? this.ruleForm.activityTime[1]
            : `${this.ruleForm.activityTime[1]} 00:00:00`;
        const formData = {
          activityType: '2',
          activityInitiator: '1',
          activityInitiatorId: '0',
          activityName: this.ruleForm.activityName,
          outName: this.ruleForm.outName,
          activityBeginTime: startTime,
          activityEndTime: endTime,
          activityRemark: this.ruleForm.activityRemark || '',
          activityRule: this.ruleForm.activityRule,
          limitType: this.ruleForm.limitType,
          limitCount: this.ruleForm.limitCount,
          scopeType: this.ruleForm.scopeType,
          stationIdList: this.ruleForm.stationIdList,
          //  rewardList: this.ruleForm.rewardList,
        };
        // 编辑/copy
        if (this.editModel === 'edit') {
          formData.activityId = this.activityId;
        }
        return formData;
      },
      /**
       * 输入框数量校验
       */
      checkCouponNumber(rule, value, callback) {
        return callback();
        // 输入的所有数据
        /*    const result = this.$refs.combination.dealResult();
        // 当前行index
        const rowIndex = rule.field.split('.')[1];
        // 选择的优惠券id
        const { couponId = '' } = result[rowIndex];

        if (!couponId) {
          return callback();
        }
        const [coupon] = this.coupons.filter(item => item.id === couponId);

        return Number(value) > coupon.leftCount
          ? callback(`超过剩余数量${coupon.leftCount}张，请重新输入`)
          : callback(); */
      },
      handleTreeCheckChange() {
        this.ruleForm.stationIdList = this.$refs.operaTree.getCheckedKeys(true);
      },
      /**
       * 活动名称唯一校验
       */
      onlyCheckName(rule, value, callback) {
        if (value) {
          couponActivity
            .checkNameOnly({
              activityName: value,
              activityType: '2',
              activityId: this.editModel === 'edit' ? this.activityId : '',
            })
            .then(res => {
              if (res?.repeatFlag) {
                // 重复
                // 校验未通过
                this.nameValidError = '活动名称已存在'; // res.resultDesc;
                callback(new Error(this.nameValidError));
                // eslint-disable-next-line no-dupe-else-if
              } else if (res?.repeatFlag === false) {
                // 校验通过
                this.nameValidError = '';
                callback();
              }
            })
            .catch(() => {});
        }
      },
      /**
       * 获取文件
       */
      getFile(file, fileList, isCheck) {
        console.log('file, fileList', file, fileList, isCheck);
        // this.ruleForm.activityRule = fileList && file;
        // this.$refs.ruleForm.validateField('activityRule');
        // eslint-disable-next-line no-unused-expressions
        isCheck && this.uploadFile(file);
      },
      /**
       * 获取文件
       */
      handleUpload(file, fileList, isCheck) {
        if (isCheck) {
          const formData = new FormData();
          const param = {
            file: file.raw,
            bizName: '',
          };
          console.log('param', param);
          // 处理入参格式
          Object.entries(param).forEach(([k, v]) => {
            formData.append(k, v);
          });
          uploadUtils.uploadImg(formData).then(res => {
            this.ruleForm.activityRule = res;
            this.$refs.ruleForm.validateField('ykcupload');
          });
        }
      },
      // 登录logo删除
      handleRemove() {
        this.ruleForm.activityImage = undefined;
      },
      /**
       * 上传图片
       */
      uploadFile(file) {
        const formData = new FormData();
        // 处理入参格式
        Object.entries({ file: file.raw }).forEach(([k, v]) => {
          formData.append(k, v);
        });
        // 请求调用
        this.$axios({
          headers: {
            'Content-Type': false,
          },
          url: 'URL_SCAN_UPLOADFILE',
          data: formData,
        })
          .then(res => {
            console.log(res);
            this.ruleForm.activityRule = res.body;
          })
          .catch(() => {
            this.ruleForm.activityRule = '';
            this.$refs.ykcupload.fileList = [];
          });
      },
      /**
       * 重置数据
       */
      resetRuleForm() {
        this.activityId = '';
        this.setFile = [];
        this.setData = [];
        this.ruleForm = {
          activityName: '',
          outName: '',
          activityTime: [],
          limitedType: '1',
          limitCount: '',
          activityRemark: '',
          activityRule: '',
        };
      },

      /**
       * 取消操作
       */
      beforeCancel(drawerDone) {
        this.$dialog({
          dialogType: 'feedback',
          showTitle: false,
          closeOnClickModal: false,
          desc: '取消后操作将不被保存，确认是否取消？',
          onConfirm: done => {
            // 关闭弹框
            done();
            // 关闭抽屉
            drawerDone();
            this.resetRuleForm();
          },
        });
      },
      /**
       * 保存操作
       */
      async beforeDrawer() {
        console.log('this.activityId', this.activityId);
        const ruleForm = { ...this.ruleForm };
        const { data, validState } = await this.$refs.combination.getResult();
        this.$refs.ruleForm.validate(valid => {
          if (valid && validState) {
            ruleForm.couponList = this.resetdata(data) || [];
            const [startTime, endTime] = ruleForm.activityTime || [];
            ruleForm.startTime = startTime;
            ruleForm.endTime = endTime;
            if (this.activityId) {
              ruleForm.activityId = this.activityId;
            }
            delete ruleForm.activityTime;
            this.$axios({
              url: this.activityId ? 'URL_SCAN_ACTIVITY_EDIT' : 'URL_SCAN_ACTIVITY_ADD',
              headers: {
                Operation: this.activityId ? 'edit' : 'add',
              },
              data: {
                body: ruleForm,
              },
            })
              .then(res => {
                console.log('data', res);
                this.$emit('change', false);
                this.$emit('update-data');
                this.resetRuleForm();
                // 重新获取优惠券(优惠券数据变更了)
                this.getCouponAndFleet(true);
              })
              .catch(() => {});
          }
        });
      },
      handleChangeShow(v) {
        this.$emit('change', v);
        console.log('drawerVisible', this.drawerVisible);
      },
      /**
       * 获取车队/优惠券数据
       */
      getCouponAndFleet(isres) {
        const axiosArr = [
          this.$axios({
            url: 'URL_QUERYCOUPON_FORCTPACTIVITY',
            data: {
              body: {},
            },
          }),
          !isres &&
            this.$axios({
              url: 'URL_QUERY_FLEET_SUBORGTREE',
              //       data: { body: { mainOrgId: sessionGetItem('orgId') } },
            }),
        ];
        this.$axios
          .all(axiosArr)
          .then(
            this.$axios.spread((resCoupon, res) => {
              const couponArr = [];
              resCoupon.body.forEach(item => {
                couponArr.push({
                  id: item.couponId,
                  name: item.couponName,
                  leftCount: item.leftCount,
                });
              });
              this.coupons = couponArr;
              this.iscoupons = true;
              if (res && res.body) {
                this.fleetOrg = res.body || [];
              }
            })
          )
          .catch(() => {
            this.iscoupons = true;
          });
      },
      resetdata(data) {
        this.coupons.forEach(item => {
          data.forEach(o => {
            if (item.id === o.couponId) {
              o.couponName = item.name;
            }
          });
        });
        return data;
      },
      changeAvailable() {
        // 清楚选择的电站数据
        this.ruleForm.stationIdList = [];
      },
      /**
       * 获取电站
       */
      getCouponAndTree() {
        const requests = [];
        const cityTreeRequest = treeUtils.findCityStationTree({});
        const operatorTreeRequest = treeUtils.operatorStations({});
        const stationTreeRequest = stationLabelApi.queryStationTree({});
        requests.push(cityTreeRequest);
        requests.push(operatorTreeRequest);
        requests.push(stationTreeRequest);
        Promise.all(requests)
          .then(([cityTreeData, operatorTreeData, stationTreeData]) => {
            this.treeCityData = cityTreeData;
            this.treeOperatorData = this.transOperatorStations(operatorTreeData);
            this.treeStationData = stationTreeData;
            this.getData();
          })
          .catch(() => {
            this.treeCityData = [];
            this.treeOperatorData = [];
            this.treeStationData = [];
          });
      },
      // 处理商户数据
      transOperatorStations(listData = []) {
        const kayMap = { operatorName: 'stationName', operatorId: 'stationId' };
        const setEmpty = (data, keyMap) => {
          const objs = Object.keys(data).reduce((newData, key) => {
            const newKey = keyMap[key] || key;
            newData[newKey] = data[key];
            return newData;
          }, {});
          return objs;
        };

        return listData.map(item => {
          return setEmpty(item, kayMap);
        });
      },
    },
  };
</script>
<style lang="scss">
  .drawer-main-body {
    .limited-wrap {
      display: flex;

      .ykc-form-item {
        flex: 1;
      }

      .ykc-dropdown {
        width: 150px;
        margin-left: 10px;
        margin-top: 26px;
      }
    }
  }
</style>
