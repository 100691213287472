<!--
 * @Author: wang peng
 * @Description: 电站列表
-->
<template>
  <scroll-layout class="fleet-match-list">
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @clean="handleSearchClean"
        @click="handleSearchConfirm"></ykc-search>
    </template>
    <ykc-table
      ref="YkcTable"
      operateFixedType="right"
      :data="tableData"
      :title="tableTitle"
      :columns="tableColumns"
      :operateButtons="tableOperateButtons.filter(item => item.enabled())">
      <template #headerButtons>
        <div class="table-header-button">
          <ykc-button v-rbac="'asset:station:direct:add'" type="plain" @click="customColumns">
            自定义列
          </ykc-button>
          <!-- v-rbac="'asset:station:direct:column'" -->
          <ykc-button v-rbac="'asset:station:direct:add'" type="plain" @click="add">
            新增
          </ykc-button>
          <ykc-button v-rbac="'asset:station:direct:export'" type="plain" @click="doExportFile">
            导出
          </ykc-button>
        </div>
      </template>

      <ykc-pagination
        align="right"
        slot="pagination"
        ref="YkcPagination"
        :total="pageInfo.total"
        :page-size.sync="pageInfo.size"
        :current-page.sync="pageInfo.current"
        @size-change="handlePaginationSizeChange"
        @current-change="handlePaginationCurrentChange"></ykc-pagination>
    </ykc-table>
    <!--自定义列弹窗 -->
    <ykc-operation-dialog
      title="自定义列"
      show-footer
      :show.sync="showDialog"
      :before-close="onCLose"
      :before-cancel="onCLose"
      :before-confirm="beforeConfirm">
      <div slot="content">
        <el-form
          size="mini"
          label-width="80px"
          ref="customForm"
          class="customForm"
          :model="customForm"
          :rules="customRules">
          <el-form-item label="列选项" prop="checkOption">
            <ykc-checkbox
              :configSet="{
                label: 'label',
                value: 'value',
              }"
              :data="customData"
              v-model="customForm.checkOption"
              @change="checkboxChange"></ykc-checkbox>
          </el-form-item>
        </el-form>
      </div>
    </ykc-operation-dialog>
    <ykc-drawer
      :title="drawerTitle"
      :show.sync="showDrawer"
      :on-close="onAddDrawerClose"
      :before-cancel="onAddDrawerCancel">
      <template #footer>
        <div class="drawer-button-wrap">
          <template v-for="(btn, i) in drawerButtons">
            <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handler">
              {{ btn.label }}
            </ykc-button>
          </template>
        </div>
      </template>
      <div class="drawer-main-body" v-if="showDrawer">
        <ImportData ref="addOrEdit" v-if="drawerType === 'import'"></ImportData>
        <AddOrEdit
          ref="addOrEdit"
          v-if="drawerType === 'add' || drawerType === 'edit' || drawerType === 'copy'"
          @changeMaxIndex="changeMaxIndex"
          :id="stationId"
          :drawerType="drawerType"
          :active="stepsIndex"></AddOrEdit>
      </div>
    </ykc-drawer>
  </scroll-layout>
</template>
<script>
  import { offlineExport, code, localSetItem, sessionGetItem, localGetItem } from '@/utils';
  import YkcDialog from '@/components/ykc-ui/dialog';
  import {
    stationPage,
    directPage,
    propertySettleManage,
    investmentData,
    treeUtils,
  } from '@/service/apis';
  import AddOrEdit from './AddOrEdit.vue';
  // import ImportData from './ImportData.vue';

  export default {
    name: 'directConnectionStationList',
    components: {
      AddOrEdit,
      // ImportData,
    },
    inject: ['reload'],
    data() {
      return {
        showDialog: false,
        drawerType: '',
        stationId: null,
        showDrawer: false,
        maxIndex: 3,
        stepsIndex: 0,
        OperatorListData: [], // 归属商户
        propertyData: [], // 所属物业
        investData: [], // 所属投资方
        addressData: [], // 省市区
        isProperty: false,
        searchParams: {
          type: 1,
          name: '',
          detailAddress: '',
          startStatus: '',
          stationCode: '',
          stationLabelList: [],
          stationOperatorId: '',
          propertyId: '',
          investorId: '',
          createTime: [],
          ids: [],
          provinceId: '',
          cityId: '',
          districtId: '',
          startTime: '',
          endTime: '',
        },
        customForm: {
          checkOption: [1, 2, 3, 4, 5, 6, 7, 8, 9],
        },
        customData: [
          {
            value: 1,
            label: '电站编码',
          },
          {
            value: 2,
            label: '电站名称',
          },
          {
            value: 3,
            label: '电站地址',
          },
          {
            value: 10,
            label: '测试站',
          },
          {
            value: 4,
            label: '入驻时间',
          },
          {
            value: 5,
            label: '归属商户',
          },
          {
            value: 8,
            label: '物业',
          },
          {
            value: 9,
            label: '投资方',
          },
          {
            value: 11,
            label: '场地名称',
          },
          {
            value: 6,
            label: '终端数量',
          },
          {
            value: 7,
            label: '电站状态',
          },
        ],
        customRules: {
          checkOption: [{ required: true, message: '请至少选择一个列名称', trigger: 'blur' }],
        },
        tableTitle: '电站列表',
        tableData: [],
        tableOperateButtons: [], // 列表操作
        tableColumns: [
          { label: '电站编码', prop: 'stationCode', minWidth: '200px', id: 1 },
          {
            label: '电站名称',
            prop: 'name',
            id: 2,
            minWidth: '250px',
            title: false,
            // scopedSlots: {
            //   default: ({ row }) => {
            //     return (
            //       <el-tooltip effect="dark" content={row.name} placement="top-start">
            //         <span>{row.name}</span>
            //       </el-tooltip>
            //     );
            //   },
            // },
          },
          {
            label: '电站地址',
            prop: 'detailAddress',
            id: 3,
            title: false,
            // scopedSlots: {
            //   defaultTitle: () => '',
            //   default: ({ row }) => {
            //     return (
            //       <el-tooltip effect="dark" content={row.detailAddress} placement="top-start">
            //         <span>{row.detailAddress}</span>
            //       </el-tooltip>
            //     );
            //   },
            // },
            minWidth: '250px',
          },
          {
            label: '测试站',
            prop: 'isTestStation',
            minWidth: '100px',
            id: 10,
            scopedSlots: {
              default: ({ row }) => {
                return row.isTestStation === 1 ? '是' : '否';
              },
            },
          },
          { label: '入驻时间', prop: 'enterTime', minWidth: '200px', id: 4 },
          { label: '归属商户', prop: 'operatorName', minWidth: '200px', id: 5 },
          { label: '物业', prop: 'propertyName', minWidth: '150px', id: 8 },
          { label: '投资方', prop: 'investorName', minWidth: '150px', id: 9 },
          { label: '场地名称', prop: 'siteName', minWidth: '150px', id: 11 },
          {
            label: '终端数量',
            prop: 'directTerminalNum',
            id: 6,
            scopedSlots: {
              default: ({ row }) => {
                return (
                  <div class="flex">
                    <div style="margin-right:4px">
                      <span class="row-item row-green">直</span>
                      <span class="row-item-txt">{row.directTerminalNum ?? '--'}</span>
                    </div>
                    <div>
                      <span class="row-item row-blue">交</span>{' '}
                      <span class="row-item-txt">{row.alternateTerminalNum ?? '--'}</span>
                    </div>
                  </div>
                );
              },
            },

            minWidth: '200px',
          },
          {
            label: '电站状态',
            prop: 'startStatus',
            id: 7,
            scopedSlots: {
              default: ({ row }) => {
                const startStatus = row.startStatus === 1 ? 'status-blue' : 'status-red';
                return (
                  <span class={['userStatus', startStatus]}>
                    {row.startStatus === 1 ? '启用' : '停用'}
                  </span>
                );
              },
            },
            minWidth: '100px',
          },
        ],
        pageInfo: {
          current: 1,
          size: 10,
        },
        drawerButtons: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => true,
            handler: () => {
              YkcDialog({
                showTitle: false,
                showFooter: true,
                dialogType: 'feedback',
                desc: '取消后操作将不被保存，确认是否取消？',
                onCancel: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                },
                onConfirm: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                  this.stepsIndex = 0;
                  this.showDrawer = false; // 隐藏抽屉
                },
              });
            },
          },
          {
            label: '上一步',
            enabled: () => {
              return this.stepsIndex !== 0;
            },
            disabled: () => false,
            handler: () => {
              this.stepsIndex--;
            },
          },
          {
            label: '下一步',
            enabled: () => {
              return this.stepsIndex !== this.maxIndex;
            },
            disabled: () => false,
            handler: () => {
              this.$refs.addOrEdit.validateForm().then(() => {
                this.stepsIndex++;
              });
            },
          },
          {
            label: '保存',
            enabled: () => {
              return this.stepsIndex === this.maxIndex;
            },
            handler: () => {
              this.$refs.addOrEdit
                .submitForm()
                .then(() => {
                  this.stepsIndex = 0;
                  this.showDrawer = false;
                  this.requestList();
                })
                .finally(() => {});
            },
          },
        ],
      };
    },
    computed: {
      searchData() {
        const all = { id: '', name: '全部' };
        return [
          {
            comName: 'YkcInput',
            key: 'name',
            label: '电站名称',
            placeholder: '请输入电站名称',
          },
          {
            comName: 'YkcInput',
            key: 'detailAddress',
            label: '电站地址',
            placeholder: '请输入电站地址',
          },
          {
            comName: 'YkcInput',
            key: 'stationCode',
            label: '电站编码',
            placeholder: '请输入电站编码',
          },
          {
            comName: 'YkcDropdown',
            key: 'startStatus',
            label: '启停状态',
            placeholder: '请选择启停状态',
            data: [
              all,
              ...[
                { id: '1', name: '启用' },
                { id: '0', name: '停用' },
              ],
            ],
          },
          {
            multiple: true,
            comName: 'YkcDropdown',
            key: 'stationLabelList',
            label: '电站标签',
            placeholder: '请选择电站标签',
            data: [
              { id: '1', name: '免费WIFI' },
              { id: '2', name: '空调休息室' },
              { id: '3', name: '按摩室' },
              { id: '4', name: '便利店' },
              { id: '5', name: '洗车' },
              { id: '6', name: '引用水' },
              { id: '7', name: '厕所' },
              { id: '8', name: '快餐' },
              { id: '9', name: '自动售货机' },
              { id: '10', name: '雨棚' },
            ],
          },
          {
            comName: 'YkcDropdown',
            key: 'stationOperatorId',
            label: '归属商户',
            placeholder: '请选择归属商户',
            data: this.OperatorListData,
          },
          {
            comName: 'YkcDropdown',
            key: 'propertyId',
            label: '所属物业',
            placeholder: '请选择所属物业',

            data: this.propertyData,
          },
          {
            comName: 'YkcDropdown',
            key: 'investorId',
            label: '所属投资方',
            placeholder: '请选择所属投资方',
            data: this.investData,
          },
          {
            comName: 'YkcDatePicker',
            type: 'daterange',
            format: 'yyyy-MM-dd',
            key: 'createTime',
            label: '入驻时间',
          },
          {
            comName: 'YkcCascader',
            key: 'ids',
            label: '省市区',
            placeholder: '请选择省市区',
            configSet: {
              label: 'name',
              value: 'id',
              children: 'subNodes',
            },
            options: this.addressData,
            filterable: true,
            clearable: true,
            checkStrictly: true,
          },
        ];
      },
      drawerTitle() {
        let txt = '';
        if (this.drawerType === 'add' && this.drawerType === 'copy') {
          txt = `直联电站新增`;
        } else if (this.drawerType === 'edit') {
          txt = `直联电站编辑`;
        } else if (this.drawerType === 'import') {
          txt = `批量新增`;
        }
        return txt;
      },
    },
    created() {
      const { id } = this.$route.query;
      this.isProperty = JSON.parse(sessionGetItem('propertyFlag'));
      if (this.isProperty) {
        this.searchParams.propertyId = JSON.parse(sessionGetItem('propertyId'));
      }
      this.searchParams.stationOperatorId = id;
      // 自定义列处理
      const customForm = localGetItem('directConnectCustomForm');

      if (customForm) {
        this.customForm.checkOption = customForm;
        this.tableColumns = this.tableColumns.filter(item => {
          return customForm.includes(item.id);
        });
      } else {
        this.tableColumns = this.tableColumns.filter(item => {
          return ![11].includes(item.id);
        });
      }
      this.requestOperatorList();
      this.requestPropertyList();
      this.requestInvestList();
      this.requestGetDistrict();
      this.requestList();
      this.initData();
    },
    methods: {
      /**
       * 初始数据
       */
      initData() {
        this.tableOperateButtons = [
          {
            id: '2',
            text: '详情',
            enabled: () => code('asset:station:direct:detail'),
            handleClick: (btn, { row }) => {
              this.$router.push({
                path: '/plantAssets/powerStationManagement/directConnectionStation/Detail',
                query: { stationId: row.stationId },
              });
            },
          },
          {
            id: '3',
            text: '价格策略',
            enabled: () => code('URL:operation:price:charge'),
            handleClick: (btn, { row }) => {
              const routeUrl = this.$router.resolve({
                path: '/operationCenter/priceManage/chargingPriceStrategy',
                query: {
                  stationName: row.name,
                },
              });
              window.open(routeUrl.href, '_blank');
            },
          },
          {
            id: '4',
            text: '电站活动',
            enabled: () => code('URL:marketing:platform:price'),
            handleClick: (btn, { row }) => {
              const routeUrl = this.$router.resolve({
                path: '/marketingCenter/platformMarketingTools/marketingActivity/platformPriceActivity',
                query: {
                  stationName: row.name,
                },
              });
              window.open(routeUrl.href, '_blank');
            },
          },
          {
            enabled: () => code('asset:station:direct:edit'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                onClick={() => {
                  this.stationId = row.stationId;
                  this.drawerType = 'edit';
                  this.showDrawer = true;
                }}>
                编辑
              </ykc-button>
            ),
          },
          {
            enabled: () => code('asset:station:direct:edit'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                onClick={() => {
                  this.stationId = row.stationId;
                  this.drawerType = 'copy';
                  this.showDrawer = true;
                }}>
                复制
              </ykc-button>
            ),
          },
          {
            enabled: () => code('asset:station:direct:enable'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                disabled={row.startStatus === 1}
                onClick={() => {
                  this.doAbleActivity(row);
                }}>
                启用
              </ykc-button>
            ),
          },
          {
            enabled: () => code('asset:station:direct:enable'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                disabled={row.startStatus === 0}
                onClick={() => {
                  this.doDisableActivity(row);
                }}>
                停用
              </ykc-button>
            ),
          },
          {
            enabled: () => code('asset:station:direct:setTest'),
            // enabled: () => true,
            render: (h, data, { row }) =>
              row.isTestStation === 1 || row.isPrivatePile === 1 ? (
                ''
              ) : (
                <ykc-button
                  type="text"
                  onClick={() => {
                    this.setTestStation(row.stationId);
                  }}>
                  设为测试站
                </ykc-button>
              ),
          },
        ];
      },
      /**
       * 新增
       * */
      add() {
        this.stepsIndex = 0;
        this.stationId = null;
        this.drawerType = 'add';
        this.showDrawer = true;
      },
      /**
       * 导出
       */
      doExportFile() {
        offlineExport(
          {
            downloadType: 'station_export',
            jsonNode: {
              ...this.searchParams,
            },
          },
          this.pageInfo.total
        );
      },
      /**
       * @desc 单个启用
       * */
      doAbleActivity(row) {
        YkcDialog({
          showTitle: false,
          showFooter: true,
          dialogType: 'feedback',
          desc: '确认启用该电站吗？',
          onCancel: done => {
            console.log(done);
            done();
          },
          onConfirm: done => {
            console.log(done);
            stationPage
              .dirStatusSwitch({ stationIdList: [row.stationId], startStatus: 1 })
              .then(res => {
                console.log(res);
                this.$message.success('电站启用成功');
                this.requestList();
              })
              .catch(err => {
                console.log(err);
              });
            done();
          },
        });
      },
      /**
       * @desc 单个停用
       * */
      doDisableActivity(row) {
        YkcDialog({
          showTitle: false,
          showFooter: true,
          dialogType: 'feedback',
          desc: '停用后，电站将不对外显示，同时无法启动充电！不影响正在充电的订单',
          onCancel: done => {
            console.log(done);
            done();
          },
          onConfirm: done => {
            console.log(done);
            stationPage
              .dirStatusSwitch({ stationIdList: [row.stationId], startStatus: 0 })
              .then(res => {
                console.log(res);
                this.$message.success('方案停用成功');
                this.requestList();
              })
              .catch(err => {
                console.log(err);
              });
            done();
          },
        });
      },
      /**
       * 点击筛选查询按钮
       * */
      handleSearchConfirm(searchData) {
        Object.assign(this.searchParams, searchData);
        if (searchData.createTime && searchData.createTime.length) {
          [this.searchParams.startTime, this.searchParams.endTime] = searchData.createTime;
        }
        if (searchData.ids && searchData.ids.length) {
          [this.searchParams.provinceId, this.searchParams.cityId, this.searchParams.districtId] =
            searchData.ids;
        }
        this.pageInfo.current = 1;
        this.searchParams.type = 1;
        this.requestList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
        this.searchParams.type = 1;
      },
      /**
       *
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.requestList();
      },
      /**
       *
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.requestList();
      },
      /**
       *
       * */
      onAddDrawerClose(done) {
        done();
      },
      /**
       *
       * */
      changeMaxIndex(index) {
        console.log('index', index);
        this.maxIndex = index;
      },
      /**
       * @desc 点击抽屉取消按钮 callback
       * */
      onAddDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: dialogDone => {
            dialogDone();
          },
          onConfirm: dialogDone => {
            dialogDone();
            drawerDone();
          },
        });
      },
      /**
       * 获取归属商户列表
       */
      requestOperatorList() {
        directPage
          .userOperatorList({ operatorType: '1' })
          .then(res => {
            const params = res;
            params.map(item => {
              return this.OperatorListData.push({
                id: item.operatorId,
                name: item.name,
              });
            });
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 获取物业列表
       */
      requestPropertyList() {
        propertySettleManage
          .propertyList({
            current: 1,
            size: 99999,
          })
          .then(res => {
            console.log('res', res);
            this.propertyData = (res || []).map(item => {
              return {
                id: item.id,
                name: item.propertyName,
              };
            });
            if (this.isProperty) {
              this.searchParams.propertyId = res[0].id;
            }
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 设置为测试站
       */
      setTestStation(stationId) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '是否确定设为测试站？',
          onCancel: dialogDone => {
            dialogDone();
          },
          onConfirm: dialogDone => {
            stationPage
              .setStationTest({ stationId })
              .then(res => {
                this.$message.success('设置成功');
                this.requestList();
                dialogDone();
              })
              .catch(err => {
                console.log(err);
              });
          },
        });
      },
      /**
       * 获取投资方列表
       */
      requestInvestList() {
        investmentData
          .investmentList({
            current: 1,
            size: 99999,
          })
          .then(res => {
            console.log('res', res);
            const params = res.records;
            params.map(item => {
              return this.investData.push({
                id: item.id,
                name: item.investorName,
              });
            });
          })
          .catch(err => {
            console.log(err);
          });
      },

      /**
       * 获取区域数据
       */
      requestGetDistrict() {
        treeUtils
          .getDistrict({})
          .then(res => {
            this.addressData = res.districts;
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 请求列表数据
       */
      requestList() {
        const reqParams = {
          ...this.searchParams,
          ...this.pageInfo,
        };
        stationPage
          .dirFindByPage(reqParams)
          .then(res => {
            this.tableData = res.records;
            this.pageInfo.total = res.total || 0;
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 自定义列
       * */
      customColumns() {
        this.showDialog = true;
      },
      /**
       * 组件change方法调用信息提示
       */
      checkboxChange(e) {
        this.customForm.checkOption = e;
        console.log('this.customForm+++', this.customForm);
      },
      /**
       *  弹窗确认
       * */
      beforeConfirm(done) {
        console.log('确认', this.customForm);
        this.$refs.customForm.validate(valid => {
          if (valid) {
            localSetItem('directConnectCustomForm', JSON.stringify(this.customForm.checkOption));
            done();
            this.reload();
          }
        });
      },
      /**
       * 弹窗关闭
       * */
      onCLose() {
        this.showDialog = false;
      },
    },
  };
</script>
<style lang="scss" scoped>
  .ykc-table {
    .el-table .el-table__row {
      .cell .userStatus {
        padding-left: 8px;
        position: relative;
      }

      .cell .userStatus::after {
        display: block;
        content: '';
        width: 5px;
        height: 5px;
        border-radius: 100%;
        background: #d0021b;
        position: absolute;
        top: 5.5px;
        left: 0;
      }

      .cell .status-blue::after {
        background: #4d8cfd;
      }

      .cell .status-green::after {
        background: #02c498;
      }

      .cell .status-black::after {
        background: #9b9b9b;
      }
    }

    .sealBtn {
      color: #4d8cfd;
      cursor: pointer;
    }

    .row-item {
      padding: 0px 4px;
      font-size: 10px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      border-radius: 7px;
      display: inline-block;
    }

    .row-item-txt {
      padding: 0 4px;
      min-width: 30px;
      text-align: center;
      display: inline-block;
    }

    .row-green {
      background: linear-gradient(180deg, #07c160 0%, #02e495 100%);
    }

    .row-blue {
      background: linear-gradient(180deg, #1779fe 0%, #72aeff 100%);
    }
  }

  ::v-deep.ykc-checkbox .icon-check {
    top: 8px;
  }

  ::v-deep.customForm .el-form-item,
  ::v-deep.customForm .el-form-item--mini.el-form-item,
  .el-form-item--small.el-form-item {
    margin-bottom: 0;
  }
</style>
