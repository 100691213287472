<template>
  <ykc-form :model="ruleForm" :rules="rules" ref="ruleForm" class="el-form-wrap">
    <ykc-form-item label="电站名称">
      <div>
        {{ currentRow.stationName }}
      </div>
    </ykc-form-item>

    <ykc-form-item label="平台服务费分成" required>
      <div class="_line">
        <ykc-dropdown
          placeholder="请选择"
          style="width: 80px"
          :clearable="false"
          :data="typeData"
          v-model="ruleForm.splitType"></ykc-dropdown>
        <ykc-form-item prop="number" class="noStyle">
          <ykc-input
            style="width: 200px"
            v-model="ruleForm.number"
            type="number"
            :placeholder="`请输入${ruleForm.splitType === 2 ? '折扣' : '单价'}`">
            <template slot="append">
              {{ appendRateType(ruleForm.splitType) }}
            </template>
          </ykc-input>
        </ykc-form-item>
        <span>每年收满</span>
        <ykc-form-item prop="capAmount" class="noStyle">
          <ykc-input
            style="width: 120px"
            v-model="ruleForm.capAmount"
            type="capAmount"
            placeholder="请输入数字">
            <template slot="append">元</template>
          </ykc-input>
        </ykc-form-item>
        <span>后,不再收取</span>
      </div>
    </ykc-form-item>

    <ykc-form-item label="允许桩主设置电费区间" required>
      <div class="_line">
        <ykc-form-item prop="elecMin" class="noStyle">
          <ykc-input
            v-model="ruleForm.elecMin"
            type="number"
            style="width: 270px"
            placeholder="请输入数字">
            <template slot="append">元</template>
          </ykc-input>
        </ykc-form-item>
        <span>~</span>
        <ykc-form-item prop="elecMax" class="noStyle">
          <ykc-input
            v-model="ruleForm.elecMax"
            style="width: 270px"
            type="number"
            placeholder="请输入数字">
            <template slot="append">元</template>
          </ykc-input>
        </ykc-form-item>
      </div>
    </ykc-form-item>
    <ykc-form-item label="允许桩主设置服务费区间" required>
      <div class="_line">
        <ykc-form-item prop="serviceMin" class="noStyle">
          <ykc-input
            v-model="ruleForm.serviceMin"
            style="width: 270px"
            type="number"
            placeholder="请输入数字">
            <template slot="append">元</template>
          </ykc-input>
        </ykc-form-item>
        <span>~</span>
        <ykc-form-item prop="serviceMax" class="noStyle">
          <ykc-input
            v-model="ruleForm.serviceMax"
            style="width: 270px"
            type="number"
            placeholder="请输入数字">
            <template slot="append">元</template>
          </ykc-input>
        </ykc-form-item>
      </div>
    </ykc-form-item>
  </ykc-form>
</template>

<script>
  import { privatePileShareApi } from '@/service/apis';

  export default {
    props: ['currentRow'],
    data() {
      const regex = /^\d+(\.\d{1,4})?$/;
      return {
        typeData: [
          {
            id: 1,
            name: '度数',
          },
          {
            id: 2,
            name: '折扣',
          },
        ],
        ruleForm: {
          splitType: 1,
          number: '',
          capAmount: '',
          serviceRate: '',
          elecMax: '',
          elecMin: '',
          serviceMax: '',
          serviceMin: '',
        },
        rules: {
          capAmount: [
            { required: true, message: '请输入封顶金额', trigger: 'blur' },
            {
              validator: (rule, val, cb) => {
                const value = Number(val || 0);
                if (Number.isNaN(value)) {
                  return cb(new Error('只能输入数字'));
                }
                if (value < 0) {
                  return cb(new Error('不能小于0'));
                }
                if (!regex.test(val)) {
                  cb(new Error('最多四位小数'));
                }

                return cb();
              },
              trigger: 'blur',
            },
          ],
          number: [
            { required: true, message: '请输入电费分成', trigger: 'blur' },
            {
              validator: (_, val, cb) => {
                const value = Number(val || 0);
                if (Number.isNaN(value)) {
                  return cb(new Error('只能输入数字'));
                }
                if (value < 0) {
                  return cb(new Error('不能小于0'));
                }
                if (!regex.test(val)) {
                  cb(new Error('最多四位小数'));
                }
                if (value > 100) {
                  cb(new Error('不能大于100'));
                }
                return cb();
              },
              trigger: 'blur',
            },
          ],
          elecMin: [
            { required: true, message: '请输入电费最小金额', trigger: 'blur' },
            {
              validator: (_, val, cb) => {
                const value = Number(val || 0);
                if (Number.isNaN(value)) {
                  return cb(new Error('只能输入数字'));
                }
                if (value < 0) {
                  return cb(new Error('不能小于0'));
                }
                if (!regex.test(val)) {
                  cb(new Error('最多四位小数'));
                }

                if (this.ruleForm.elecMax && value > this.ruleForm.elecMax) {
                  return cb(new Error('不能大于设置的电费最大值'));
                }
                return cb();
              },
              trigger: 'blur',
            },
          ],
          elecMax: [
            { required: true, message: '请输入电费最大金额', trigger: 'blur' },
            {
              validator: (_, val, cb) => {
                const value = Number(val || 0);
                if (Number.isNaN(value)) {
                  return cb(new Error('只能输入数字'));
                }
                if (value < 0) {
                  return cb(new Error('不能小于0'));
                }
                if (!regex.test(val)) {
                  cb(new Error('最多四位小数'));
                }

                if (this.ruleForm.elecMin && value < this.ruleForm.elecMin) {
                  return cb(new Error('不能小于设置的电费最小值'));
                }
                return cb();
              },
              trigger: 'blur',
            },
          ],
          serviceMin: [
            { required: true, message: '请输入服务费最小金额', trigger: 'blur' },

            {
              validator: (_, val, cb) => {
                const value = Number(val || 0);
                if (Number.isNaN(value)) {
                  return cb(new Error('只能输入数字'));
                }
                if (value < 0) {
                  return cb(new Error('不能小于0'));
                }
                if (!regex.test(val)) {
                  cb(new Error('最多四位小数'));
                }

                if (this.ruleForm.serviceMax && value > this.ruleForm.serviceMax) {
                  return cb(new Error('不能大于设置的服务费最大值'));
                }
                return cb();
              },
              trigger: 'blur',
            },
          ],
          serviceMax: [
            { required: true, message: '请输入服务费最大金额', trigger: 'blur' },
            {
              validator: (_, val, cb) => {
                const value = Number(val || 0);
                if (Number.isNaN(value)) {
                  return cb(new Error('只能输入数字'));
                }
                if (value < 0) {
                  return cb(new Error('不能小于0'));
                }
                if (!regex.test(val)) {
                  cb(new Error('最多四位小数'));
                }

                if (this.ruleForm.serviceMin && value < this.ruleForm.serviceMin) {
                  return cb(new Error('不能小于设置的服务费最小值'));
                }
                return cb();
              },
              trigger: 'blur',
            },
          ],
        },
      };
    },
    computed: {},
    created() {
      this.ruleForm = {
        ...this.currentRow,
      };
      // if (this.operateType === 'single') {
      //   this.ruleForm.collectFlag = this.currentRow.collectFlag || this.ruleForm.collectFlag;
      //   this.ruleForm.powerRateType = this.currentRow.powerRateType || this.ruleForm.powerRateType;
      //   this.ruleForm.powerRate = this.currentRow.powerRate || this.ruleForm.powerRate;
      //   this.ruleForm.serviceRateType =
      //     this.currentRow.serviceRateType || this.ruleForm.serviceRateType;
      //   this.ruleForm.serviceRate = this.currentRow.serviceRate || this.ruleForm.serviceRate;
      // }
    },
    methods: {
      appendRateType(type) {
        switch (type) {
          case 1:
            return '元/度';
          case 2:
            return '%';

          default:
            return '元/度';
        }
      },
      submitForm(callback) {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            const data = {
              ...this.ruleForm,
              id: this.currentRow.id,
            };
            privatePileShareApi.update(data).then(res => {
              console.log('commissionStrategy single edit', res);
              callback();
            });
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .ykc-form .ykc-form-item.noStyle ::v-deep .el-form-item {
    margin-bottom: 0;
  }
  ._line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > * {
      margin-right: 5px;
    }
  }
</style>
