// ╔════════════════════════════════════════════════════════════════╗
// ║ -----------------------------------------------------------    ║
// ║ block  start 21:06                                             ║
// ║ regexp for ykc form data validation of standard v4             ║
// ║ @author hhy                                                    ║
// ║ @date 2023-2-17                                                ║
// ║ block  end  21:06                                              ║
// ║ -----------------------------------------------------------    ║
// ╚════════════════════════════════════════════════════════════════╝

export default {
  regexp: {
    // 输入框组件
    input: {
      // 特殊字符
      specialCharacters: {
        regexp: /^[^#$%;"\\]*$/,
        // 错误提示
        errorTips: {
          // 错误
          error: txt => `请输入正确的${txt ?? '值'}（不可输入#$%;"）`,
          // 唯一
          uniq: txt => `${txt ?? '值'}已存在`,
          // 空
          empty: txt => `请输入${txt ?? '值'}`,
        },
      },
      // 单行文本
      inlineTextType: {
        regexp: /^[^#$%;"\\]{1,50}$/,
        // 错误提示
        errorTips: {
          // 错误
          error: txt => `请输入正确的${txt ?? '值'}（不可输入#$%;"）`,
          // 唯一
          uniq: txt => `${txt ?? '值'}已存在`,
          // 空
          empty: txt => `请输入${txt ?? '值'}`,
        },
      },
      // 单行文本_14位
      inlineTextType_14: {
        regexp: /^[A-Za-z0-9]{14}$/,
        // 错误提示
        errorTips: {
          // 错误
          error: txt => `请输入正确的${txt ?? '值'}（不可输入#$%;"）`,
          // 唯一
          uniq: txt => `${txt ?? '值'}已存在`,
          // 空
          empty: txt => `请输入${txt ?? '值'}`,
        },
      },
      // 单行文本_16位
      inlineTextType_16: {
        regexp: /^[A-Za-z0-9]{16}$/,
        // 错误提示
        errorTips: {
          // 错误
          error: txt => `请输入正确的${txt ?? '值'}（不可输入#$%;"）`,
          // 唯一
          uniq: txt => `${txt ?? '值'}已存在`,
          // 空
          empty: txt => `请输入${txt ?? '值'}`,
        },
      },
      // 单行文本(字母和数字组合)
      inlineTextType_num: {
        regexp: /^[A-Za-z0-9]+$/,
        // 错误提示
        errorTips: {
          // 错误
          error: txt => `请输入正确的${txt ?? '值'}（字母和数字组合）`,
          // 唯一
          uniq: txt => `${txt ?? '值'}已存在`,
          // 空
          empty: txt => `请输入${txt ?? '值'}`,
        },
      },
      // 单行文本(字母和数字-组合)
      inlineTextType_num1: {
        regexp: /^[A-Za-z0-9-\u4e00-\u9fa5]+$/,
        // 错误提示
        errorTips: {
          // 错误
          error: txt => `请输入正确的${txt ?? '值'}（字母和数组和字符-组合）`,
          // 唯一
          uniq: txt => `${txt ?? '值'}已存在`,
          // 空
          empty: txt => `请输入${txt ?? '值'}`,
        },
      },
      // 单行文本(字母和数字_组合)
      inlineTextType_num2: {
        regexp: /^[A-Za-z0-9_]+$/,
        // 错误提示
        errorTips: {
          // 错误
          error: txt => `请输入正确的${txt ?? '值'}（字母和数组组合）`,
          // 唯一
          uniq: txt => `${txt ?? '值'}已存在`,
          // 空
          empty: txt => `请输入${txt ?? '值'}`,
        },
      },
      // 正数和负数，但不带小数点数字类
      plusMinusNumberType: {
        regexp: /^[+-]?\d+$/,
        // 错误提示
        errorTips: {
          // 错误
          error: (txt, range) =>
            `请输入正确的${txt ?? '整数'}（范围：-99999999~${range ?? 99999999}）`,
          // 空
          empty: txt => `请输入${txt ?? '整数'}`,
        },
      },
      // 整数数字类(不含0)
      integerNumberType: {
        regexp: /^[1-9]\d{0,7}$/,
        // 错误提示
        errorTips: {
          // 错误
          error: (txt, range) => `请输入正确的${txt ?? '整数'}（范围：1~${range ?? 99999999}）`,
          // 空
          empty: txt => `请输入${txt ?? '整数'}`,
        },
      },
      // 整数数字类(含0)
      zeroIntegerNumberType: {
        regexp: /^([1-9]\d*|[0]{1,1})$/,
        // 错误提示
        errorTips: {
          // 错误
          error: txt => `请输入正确的${txt ?? '整数'}（范围：大于等于0的整数）`,
          // 空
          empty: txt => `请输入${txt ?? '整数'}`,
        },
      },
      // 小数数字类
      decimalNumberType: {
        regexp: /^[0-9]{1,10}(\.[0-9]{1,10})?$/,
        // 错误提示
        errorTips: {
          // 错误
          error: (txt, range) => `请输入正确的${txt ?? '数'}（范围：0~${range}）`,
          // 空
          empty: txt => `请输入${txt ?? '数'}`,
        },
      },
      // 百位内数字
      decimalNumberType_2: {
        regexp: /^[0-9]{1,2}(\.[0-9]{1,2})?$/,
        // 错误提示
        errorTips: {
          // 错误
          error: (txt, range) => `请输入正确的${txt ?? '数'}（范围：0~${range}）`,
          // 空
          empty: txt => `请输入${txt ?? '数'}`,
        },
      },
      // 11位数字
      decimalNumberType_11: {
        regexp: /^[0-9]{1,11}(\.[0-9]{1,10})?$/,
        // 错误提示
        errorTips: {
          // 错误
          error: (txt, range) => `请输入正确的${txt ?? '数'}（范围：0~${range}）`,
          // 空
          empty: txt => `请输入${txt ?? '数'}`,
        },
      },
      // 14位数字
      decimalNumberType_14: {
        regexp: /^[0-9]{1,14}(\.[0-9]{1,10})?$/,
        // 错误提示
        errorTips: {
          // 错误
          error: (txt, range) => `请输入正确的${txt ?? '数'}（范围：0~${range}）`,
          // 空
          empty: txt => `请输入${txt ?? '数'}`,
        },
      },
      // 16位数字
      decimalNumberType_16: {
        regexp: /^[0-9]{1,16}(\.[0-9]{1,10})?$/,
        // 错误提示
        errorTips: {
          // 错误
          error: (txt, range) => `请输入正确的${txt ?? '数'}（范围：0~${range}）`,
          // 空
          empty: txt => `请输入${txt ?? '数'}`,
        },
      },
      // 小数数字类(四位整数,两位小数)
      decimalFourNumberType: {
        regexp: /^(0|[1-9]\d{0,3})(\.\d{1,2})?$/,
        // 错误提示
        errorTips: {
          // 错误
          error: (txt, range) => `请输入正确的${txt ?? '数'}（范围：0~${range}）`,
          // 空
          empty: txt => `请输入${txt ?? '数'}`,
        },
      },
      // 价格类 电量类 价格类相同
      priceNumberType: {
        regexp: /^(0|[1-9]\d{0,2})(\.\d{1,4})?$/,
        // 错误提示
        errorTips: {
          // 错误
          error: (txt, range) => `请输入正确的${txt ?? '价格'}（范围：0~${range}）`,
          // 空
          empty: txt => `请输入${txt ?? '价格'}`,
        },
      },
      // 充电金额 保留两位小数
      chargeNumberType: {
        regexp: /^(0|[1-9]\d{0,6})(\.\d{2})$/,
        // 错误提示
        errorTips: {
          // 错误
          error: (txt, range) => `请输入正确的${txt ?? '价格'}（范围：0~${range}）`,
          // 空
          empty: txt => `请输入${txt ?? '价格'}`,
        },
      },
      // 百分比数字
      percentNumberType: {
        regexp: /^([0-9]|[1-9][0-9]|100)$/,
        // 错误提示
        errorTips: {
          // 错误
          error: (txt, range) => `请输入正确的${txt ?? '百分比'}（范围：0~${range ?? 100}%）`,
          // 空
          empty: txt => `请输入${txt ?? '百分比'}`,
        },
      },
      // 保留两位小数的正实数
      decimalNumberType_fixed_2: {
        regexp: /^(([1-9][0-9]*)(\.\d{1,2})?|0\.\d{1,2})$/,
        // 错误提示
        errorTips: {
          // 错误
          error: txt => `请输入正确的${txt ?? '金额'}（范围：大于0且最多保留2位小数）`,
          // 空
          empty: txt => `请输入${txt ?? '金额'}`,
        },
      },
      // 金额类
      amountNumberType: {
        regexp: /^\d{1,9}\.\d{1,2}$|^\d{1,9}$/,
        // 错误提示
        errorTips: {
          // 错误
          error: (txt, range) => `请输入正确的${txt ?? '金额'}（范围：0~${range ?? 999999999.99}）`,
          // 空
          empty: txt => `请输入${txt ?? '金额'}`,
        },
      },
      // 密码类
      passwordType: {
        regexp: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,16}$/,
        // 错误提示
        errorTips: {
          // 错误
          error: `请输入正确的密码，密码由数字、字母、特殊符号组成，区分大小写，8-16位`,
          // 空
          empty: txt => `请输入${txt ?? '密码'}`,
        },
      },
      // 链接类
      urlType: {
        regexp: /^(https?:\/\/)?[\w-]{1,100}[^#%$;"\\]+$/i,
        // 错误提示
        errorTips: {
          // 错误
          error: txt => `请输入正确的${txt ?? '链接地址'}（如：https://www.ykcccn.com）`,
          // 空
          empty: txt => `请输入${txt ?? '链接地址'}`,
        },
      },
      // 名称类
      nameType: {
        regexp: /^[\u4e00-\u9fa5_a-zA-Z0-9.]{1,50}$/,
        customRegexp: (min, max) => new RegExp(`^[\\u4e00-\\u9fa5_a-zA-Z0-9]{${min},${max}}$`),
        // 错误提示
        errorTips: {
          // 错误
          error: txt => `请输入正确的${txt ?? '名称'}（不可输入#$%;"\\）`,
          // 空
          empty: txt => `请输入${txt ?? '名称'}`,
        },
      },
      // 邮箱类
      emailType: {
        regexp: /^(.{1,50})@([0-9a-zA-Z]+)\.([a-zA-Z]{2,4})$/,
        // 错误提示
        errorTips: {
          // 错误
          error: `请输入正确格式的邮箱（如：123@ykccn.com）`,
          // 空
          empty: txt => `请输入${txt ?? '邮箱'}`,
        },
      },
      // 手机号码类 13112345678
      cellphoneType: {
        regexp: /^1\d{10}$/,
        // 错误提示
        errorTips: {
          // 错误
          error: txt => `请输入正确的${txt ?? '手机号'}（手机号码由1开头的11位数字组成）`,
          // 空
          empty: txt => `请输入${txt ?? '手机号'}`,
        },
      },
      // 座机类 025-88888888 +8613114784562
      landlinesType: {
        regexp: /^\+?[0-9-]{1,15}$/,
        // 错误提示
        errorTips: {
          // 错误
          error: txt => `请输入正确的${txt ?? '电话号码'}（电话号码由数字组成，允许输入“-”）`,
          // 空
          empty: txt => `请输入${txt ?? '电话号码'}`,
        },
      },
      // 单号类
      orderNoType: {
        regexp: /^[a-zA-Z0-9-]{1,30}$/,
        // 错误提示
        errorTips: {
          // 错误
          error: txt => `请输入正确的${txt ?? '单号'}（只能输入数字、字母、“-”）`,
          // 空
          empty: txt => `请输入${txt ?? '单号'}`,
        },
      },
      // 账号类 == 编码类
      accountType: {
        regexp: /^[0-9a-zA-Z_]{1,30}$/,
        // 错误提示
        errorTips: {
          // 错误
          error: txt => `请输入正确的${txt ?? '账号'}（只能输入数字、字母、下划线）`,
          // 空
          empty: txt => `请输入${txt ?? '账号'}`,
        },
      },
      // 车牌号码
      carLicensePlateNumberType: {
        regexp:
          /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领]{1}[A-Z]{1}[A-Z0-9]{4,5}[A-Z0-9挂学警港澳]{1}$/,
        // 错误提示
        errorTips: {
          // 错误
          error: txt => `请输入正确的${txt ?? '车牌号码'}`,
          // 空
          empty: txt => `请输入${txt ?? '车牌号码'}`,
        },
      },
      // 文本域
      textAreaType: {
        regexp: /^[^#$%;"\\]{1,500}$/,
        // 错误提示
        errorTips: {
          // 错误
          error: txt => `请输入正确的${txt ?? '内容'}（不可输入#$%;"\\）`,
          // 空
          empty: txt => `请输入${txt ?? '内容'}`,
        },
      },
      // 秘钥类
      secretType: {
        regexp: /^[\u4e00-\u9fa5_a-zA-Z0-9]{1,500}$/,
        // 错误提示
        errorTips: {
          // 错误
          error: txt => `请输入正确的${txt ?? '秘钥'}（不可输入#$%;"\\）`,
          // 空
          empty: txt => `请输入${txt ?? '秘钥'}`,
        },
      },
      // 地址类
      addressType: {
        regexp: /^[\u4E00-\u9FA5A-Za-z0-9_-]{1,100}$/,
        // 错误提示
        errorTips: {
          // 错误
          error: txt => `请输入正确的${txt ?? '地址'}`,
          // 空
          empty: txt => `请输入${txt ?? '地址'}`,
        },
      },
      // 版本号
      versionNumberType: {
        regexp: /\d{4}\.\d{1,2}\.\d{1,2}\.\d{2,}\.\d+\.\w+/,
        // 错误提示
        errorTips: {
          // 错误
          error: txt => `请输入正确的${txt ?? '版本号'}`,
          // 空
          empty: txt => `请输入${txt ?? '版本号'}`,
        },
      },
      versionNumberTypeV2: {
        //   主版本号.次版本号.补丁版本号
        regexp: /^(\d+)\.(\d+)\.(\d)+$/,
        // 错误
        error: txt => `请输入正确的${txt ?? '版本号'}`,
        // 空
        empty: txt => `请输入${txt ?? '版本号'}`,
      },
      // 社会统一信用代码
      creditCode: {
        regexp: /^[A-Za-z0-9]{18}$/,
        // 错误提示
        errorTips: {
          // 错误
          error: txt => `请输入正确的${txt ?? '社会统一信用代码'}`,
          // 空
          empty: txt => `请输入${txt ?? '社会统一信用代码'}`,
        },
      },
      // 经度
      validateLongitudeLongitude: {
        regexp: /^-?((\d|[1-9]\d|1[0-7]\d)(\.\d{1,6})?|180(\.0{1,6})?)$/,
        // 错误提示
        errorTips: {
          // 错误
          error: txt => `请输入正确的${txt ?? '经度'}`,
          // 空
          empty: txt => `请输入${txt ?? '经度'}`,
        },
      },
      // 纬度
      validateLatitude: {
        regexp: /^-?((\d|[1-8]\d)(\.\d{1,6})?|90(\.0{1,6})?)$/,
        // 错误提示
        errorTips: {
          // 错误
          error: txt => `请输入正确的${txt ?? '纬度'}`,
          // 空
          empty: txt => `请输入${txt ?? '纬度'}`,
        },
      },
    },
    // 输入联想框组件
    associativeInput: {
      regexp: /^[^#$%;"\\]{1,20}$/,
      // 错误提示
      errorTips: {
        // 错误
        error: txt => `请输入正确的${txt ?? '搜索关键字'}（不可输入#$%;"\\）`,
        // 空
        empty: txt => `请输入${txt ?? '搜索关键字'}`,
      },
    },
    // 富文本编辑器组件
    richTextEditor: {
      regexp: /^[^#$%;"\\]{1,2000}$/,
      // 错误提示
      errorTips: {
        // 错误
        error: txt => `请输入正确的${txt ?? '内容'}（不可输入#$%;"\\）`,
        // 空
        empty: txt => `请输入${txt ?? '内容'}`,
      },
    },
    // 选择框组件
    select: {},
    // 上传组件
    upload: {
      file: {
        regexp: /^[0-5]?\d(\.\d{1,2})?M$/,
        errorTips: {
          // 错误
          error: txt => `${txt ?? '文件'}格式错误；${txt ?? '文件'}大小不超过5M`,
          // 空
          empty: txt => `请上传${txt ?? '文件'}`,
        },
      },
      image: {
        /**
         * @example
         * var reg = /^[\s\S]*?\.(jpg|png|jpeg)$/i;  //匹配jpg、png、jpeg结尾的图片的正则
         * var limitSize = 2 * 1024 * 1024;   //2MB
         * if (fileSize <= limitSize && reg.test(fileName)) {
         *     //文件大小不超过2M且文件名是jpg、png、jpeg格式的图片，则上传图片
         *     uploadImage();
         * } else {
         *     alert("请上传不超过2MB的jpg、png、jpeg格式的图片");
         * }
         * */
        // 校验转换为 M 为单位的图片大小
        size: {
          regexp: /^[0-5]?\d(\.\d{1,2})?M$/,
          errorTips: {
            // 错误
            error: txt => `${txt ?? '文件'}大小不超过5M`,
            // 空
            empty: txt => `请上传${txt ?? '文件'}`,
          },
        },
        // 校验文件拓展名
        extendName: {
          regexp: /^[\s\S]*?\.(jpg|png|jpeg)$/i, // 匹配jpg、png、jpeg结尾的图片的正则,
          errorTips: {
            // 错误
            error: txt => `${txt ?? '图片'}格式错误；`,
            // 空
            empty: txt => `请上传${txt ?? '图片'}`,
          },
        },
      },
    },
  },
  tips: {
    // 默认提示
    defaultTips: {
      // 日期类型
      dateType: '选择时间',
      // 输入框类型
      inputType: txt => `请输入${txt}`,
      // 下拉框类型
      dropDownType: txt => `请输入${txt}`,
      // 搜索输入框下拉搜索类型
      searchInputDropDOwnType: '请输入搜索关键字',
    },
    // 错误提示
    errorTips: {
      // 网络异常
      networkError: {
        tip: '网络开小差了，请刷新重试',
      },
    },
  },
};
