<!--
 * @Author: wanghui@ykccn.com
 * @Date: 2023-02-14
 * @Description: 营销管理/平台营销工具/平台卡券活动/新增或者编辑(定向券)
-->
<template>
  <ykc-form :model="ruleForm" :rules="rules" ref="ruleForm">
    <ykc-form-item label="活动名称" prop="activityName">
      <ykc-input
        v-model="ruleForm.activityName"
        maxlength="50"
        placeholder="请输入活动名称"></ykc-input>
    </ykc-form-item>
    <ykc-form-item label="对外名称" prop="outName">
      <ykc-input v-model="ruleForm.outName" maxlength="50" placeholder="请输入对外名称"></ykc-input>
    </ykc-form-item>
    <ykc-form-item label="卡券设置" class="required">
      <ykc-combination-item
        required
        :basicData="basicData"
        :selectData="coupons"
        :data="setData"
        :maxlength="20"
        addText="添加卡券"
        ref="combination"></ykc-combination-item>
    </ykc-form-item>
    <ykc-form-item label="发放时间" prop="sendType">
      <ykc-radio @change="handleMode" :data="grantMethod" v-model="ruleForm.sendType"></ykc-radio>
    </ykc-form-item>
    <ykc-form-item label="发放时间" v-if="ruleForm.sendType === '2'" prop="sendTime">
      <ykc-date-picker
        type="datetime"
        onlyHour
        limitDate="limitBeforeTime"
        format="yyyy-MM-dd HH"
        @change="dateChange"
        placeholder="请选择发放时间"
        :defaultTime="defaultTime"
        v-model="ruleForm.sendTime"></ykc-date-picker>
    </ykc-form-item>
    <ykc-form-item label="用户维度" prop="userScope">
      <ykc-radio
        @change="methodChange"
        :data="[
          { id: '1', name: '按客户发送' },
          { id: '2', name: '用户分组' },
          { id: '4', name: '指定用户' },
        ]"
        v-model="ruleForm.userScope"></ykc-radio>
    </ykc-form-item>
    <ykc-form-item prop="userList" label="发放用户" v-if="ruleForm.userScope === '1'">
      <ykc-tree
        ref="treeUser"
        :data="usersData"
        :props="userProps"
        @check-change="handleUsersTree" />
    </ykc-form-item>
    <ykc-form-item prop="userGroupList" label="发放用户" v-if="ruleForm.userScope === '2'">
      <ykc-tree
        ref="treeUserGroup"
        :data="userGroupData"
        :props="userProps"
        @check-change="handleUsersGroupTree" />
    </ykc-form-item>
    <ykc-form-item
      class="file-error"
      label="选择文件"
      prop="importedUserList"
      v-if="ruleForm.userScope === '4'">
      <ykc-upload
        type="xlsx"
        :size="5"
        ref="file"
        :checkInfo="{ typeMess: '文件格式错误，请重新导入', sizeMess: '文件大小不超过' }"
        @handleChange="uploadFile"
        @handleRemove="handleRemove"
        :temText="linkText"
        :temhref="modelUrl"></ykc-upload>
    </ykc-form-item>
  </ykc-form>
</template>
<script>
  import { couponActivity, marketManage, treeUtils } from '@/service/apis';
  import regexpObj from '@/utils/regexp';

  export default {
    props: {
      editModel: {
        type: String,
        default: 'add', // add edit copy
      },
      activityId: {
        type: String,
      },
    },
    data() {
      return {
        // 车辆绑定初始数据
        selectData: [],
        basicData: [
          {
            comName: 'YkcDropdown',
            key: 'couponId',
            remote: true,
            value: '',
            placeholder: '请选择优惠券/抵用卡',
            rules: { required: true, message: '请选择优惠券/抵用卡', trigger: 'blur' },
            prepend: '卡券',
            only: true,
            data: [],
          },
          {
            comName: 'YkcInput',
            value: '',
            key: 'issueCount',
            maxlength: 8,
            prepend: '数量',
            append: '张/人',
            placeholder: '请输入卡券数量',
            rules: [
              { required: true, message: '请输入卡券数量', trigger: 'blur' },
              {
                pattern: regexpObj.regexp.input.integerNumberType.regexp,
                message: regexpObj.regexp.input.integerNumberType.errorTips.error(
                  '卡券数量',
                  99999999
                ),
                trigger: 'blur',
              },
            ],
          },
        ],
        defaultTime: '', // 默认时间
        ruleForm: {
          activityName: '',
          outName: '',
          sendType: '1',
          sendTime: '',
          userScope: '1',
          orgList: [],
          userList: [],
          userGroupList: [],
          importedUserList: [], // 导入的用户列表
        },

        rules: {
          activityName: [
            { required: true, message: '请输入活动名称', trigger: 'blur' },
            {
              pattern: regexpObj.regexp.input.nameType.regexp,
              message: regexpObj.regexp.input.nameType.errorTips.error('活动名称'),
              trigger: 'blur',
            },
            { validator: this.onlyCheckName(), trigger: 'blur' },
          ],
          outName: [{ required: true, message: '请输入对外名称', trigger: 'blur' }],
          sendType: [{ required: true, message: '请选择发放时间', trigger: 'change' }],
          sendTime: [{ required: true, message: '请选择发放时间', trigger: 'change' }],
          userScope: [{ required: true, message: '请选择发放方式', trigger: 'change' }],
          orgList: [{ required: true, message: '请选择适用用户范围设置', trigger: 'change' }],
          userList: [{ required: true, message: '请上选择用户', trigger: 'change' }],
          userGroupList: [{ required: true, message: '请选择用户分组', trigger: 'change' }],
          importedUserList: [
            { required: !this.activityId, message: '请上传文件', trigger: 'change' },
          ],
        },
        fleetOrg: [],
        treeProps: {
          label: 'name',
          children: 'subNodes',
          disabled: 'disabled',
        },
        setData: [], // 组合框初始化数据
        coupons: [],
        grantMethod: [
          {
            id: '1',
            name: '立即发放',
          },
          {
            id: '2',
            name: '定时发放',
          },
        ], // dictionary.GRANT_METHOD,
        file: [],

        usersData: [],
        userGroupData: [],
        userProps: {
          label: 'name',
          children: 'subNodes',
          disabled: 'disabled',
        },
        linkText: '用户导入.xlsx', // 模板文本
        modelUrl: `${process.env.VUE_APP_OSS}/resource/用户导入模板.xlsx`,
      };
    },

    created() {
      this.getData();
    },
    model: {
      prop: 'drawerVisible',
      event: 'change',
    },
    methods: {
      getData() {
        const requests = [];
        const requestCoupons = marketManage.activityList({
          // cardCouponType://卡券类型（1:抵用卡，2:优惠券）
          useType: '1', // 使用类型（1:普通，2:会员）
          undertakerType: '1', // 承担方类型（1:平台，2:商户）
          // undertakerId://承担商户id
          // batchNumber;//批次号
        });
        const userTreeRequest = treeUtils.operatorUsers({});
        const userTreeGroupRequest = treeUtils.operatorUserGroup({});
        requests.push(requestCoupons);
        requests.push(userTreeRequest);
        requests.push(userTreeGroupRequest);
        if (this.activityId && (this.editModel === 'edit' || this.editModel === 'copy')) {
          const requestDetail = couponActivity.orientationActivityDetail({
            activityId: this.activityId,
          });
          requests.push(requestDetail);
        }
        Promise.all(requests).then(([resCoupon, usersData, userGroup, resDetail]) => {
          if (resDetail) {
            this.detail = resDetail || {};
          }
          if (resCoupon) {
            this.coupons = [];
            if (
              this.activityId &&
              (this.detail.activityStatus === '1' || this.detail.activityStatus === '2')
            ) {
              // 只有未开始进行中，进行复制卡券
              this.detail.rewardList.forEach(item => {
                this.coupons.push({
                  cardCouponId: item.rewardId,
                  id: item.rewardId,
                  name: item.rewardName,
                  cardCouponType: item.rewardType,
                  leftCount: item.rewardCount,
                });
              });
            }
            const coupons = resCoupon.map(item => {
              // key: 'cardCouponId',
              // name: 'cardCouponName',
              const { cardCouponId, cardCouponName, remainderNum } = item;
              item.id = cardCouponId;
              item.name = cardCouponName;
              item.leftCount = remainderNum;
              return item;
            });
            this.coupons.push(...coupons);
            // 如果是选中状态
            if (this.activityId) {
              this.$nextTick(() => {
                this.setData = this.formatSetData();
              });
            }
          }
          this.formatUserTreeData(usersData || []);
          this.formatUserGroupTreeData(userGroup || []);
          this.detail = resDetail || {};
          if (this.activityId) {
            this.fillInitData();
          }
        });
      },
      formatUserTreeData(data) {
        if (data) {
          this.usersData = [];
          if (data) {
            let id = 1000000;
            // eslint-disable-next-line no-restricted-syntax
            for (const [key, users] of Object.entries(data)) {
              console.log(key, users);
              const user = {
                id: id++,
                name: key,
                subNodes: [],
                disabled: false,
              };
              users.forEach(item => {
                user.subNodes.push({
                  id: item.id,
                  name: item.name,
                });
              });
              this.usersData.push(user);
            }
          }
        }
      },
      formatUserGroupTreeData(data) {
        this.userGroupData = [];
        if (data?.length > 0) {
          data.forEach(item => {
            const userGroup = {
              id: item.id,
              name: item.groupName || '未知',
              disabled: false,
            };
            this.userGroupData.push(userGroup);
          });
        }
      },
      /**
       * 初始化form数据
       */
      fillInitData() {
        if (this.editModel === 'edit') {
          this.ruleForm.activityName = this.detail.activityName;
          this.ruleForm.outName = this.detail.outName;
          this.ruleForm.activityTime = [];
          if (this.detail.activityBeginTime) {
            this.ruleForm.activityTime.push(this.detail.activityBeginTime);
          }
          if (this.detail.activityEndTime) {
            this.ruleForm.activityTime.push(this.detail.activityEndTime);
          }
        }
        this.ruleForm.sendType = this.detail.sendType;
        this.ruleForm.sendTime = this.detail.sendTime;
        this.ruleForm.activityRemark = this.detail.activityRemark || '';
        // 用户维度
        this.ruleForm.userScope = this.detail.userScope;
        if (this.ruleForm.userScope === '1') {
          this.ruleForm.userList = this.fillTreeSelectedIds(this.detail.userScopeList || []);
          this.selectTreeNodes(this.$refs.treeUser, this.ruleForm.userList);
        } else if (this.ruleForm.userScope === '2') {
          this.ruleForm.userGroupList = this.fillTreeSelectedIds(this.detail.userScopeList || []);
          this.selectTreeNodes(this.$refs.treeUserGroup, this.ruleForm.userList);
        } else if (this.ruleForm.userScope === '4') {
          this.ruleForm.importedUserList = (this.detail.userScopeList || []).map(item => {
            return {
              dataId: item.dataId,
              dataName: item.dataName,
            };
          });
        }
      },
      /**
       * 选择树形控件
       * @param tree
       * @param selectedNodes
       */
      selectTreeNodes(tree, selectedNodes) {
        this.$nextTick(() => {
          tree.setCheckedKeys(selectedNodes, true);
        });
      },
      fillTreeSelectedIds(records) {
        const selectedIds = [];
        records.forEach(item => {
          selectedIds.push(item.dataId);
        });
        return selectedIds;
      },
      formatSetData() {
        const selectedCoupons = [];
        this.detail.rewardList.forEach(item => {
          selectedCoupons.push({
            couponId: item.rewardId,
            // name: item.rewardName, // rewardCount
            issueCount: item.rewardCount,
          });
        });
        return selectedCoupons;
      },
      submitForm() {
        return new Promise(resolve => {
          const couponValidForm = this.$refs.combination.getResult();
          const dataValidForm = this.validateAllForm();
          Promise.all([couponValidForm, dataValidForm]).then(([couponResult]) => {
            // 新增/复制/编辑
            if (couponResult.validState) {
              const submitData = this.formData();
              submitData.rewardList = this.formatCouponData(couponResult.data || []);
              couponActivity.orientationActivitySave(submitData).then(res => {
                console.log(res);
                this.$message({
                  message: '保存成功',
                  type: 'success',
                });
                resolve();
              });
            }
          });
        });
      },
      /**
       * 校验表单
       * @returns {Promise<unknown>}
       */
      validateAllForm() {
        return new Promise((resolve, reject) => {
          Promise.all(
            Object.keys(this.$refs)
              .filter(o => o.includes('ruleForm'))
              .map(o => {
                return new Promise((_resolve, _reject) => {
                  this.$refs[o].validate(valid => {
                    if (valid) {
                      if (this.isDateBeforeNow()) {
                        this.$message.warning('发送时间不能早于当前时间');
                        _reject();
                      } else {
                        _resolve();
                      }
                    } else {
                      _reject();
                    }
                  });
                });
              })
          )
            .then(() => {
              resolve();
            })
            .catch(err => {
              console.log(err);
              reject();
            });
        });
      },
      formatCouponData(selectedCoupons) {
        const couponDatas = [];
        if (selectedCoupons.length > 0) {
          selectedCoupons.forEach(selectItem => {
            const selectId = selectItem.couponId;
            for (let i = 0; i < this.coupons.length; i++) {
              const originCoupon = this.coupons[i];
              if (originCoupon.cardCouponId === selectId) {
                couponDatas.push({
                  rewardType: originCoupon.cardCouponType,
                  rewardId: selectId,
                  rewardCount: selectItem.issueCount || '',
                });
                break;
              }
            }
          });
        }
        return couponDatas;
      },
      formData() {
        const formData = {
          activityType: '3',
          activityInitiator: '1',
          activityInitiatorId: '0',
          activityName: this.ruleForm.activityName,
          outName: this.ruleForm.outName,
          sendType: this.ruleForm.sendType,
          sendTime: this.ruleForm.sendTime,
          userScope: this.ruleForm.userScope,
        };
        if (this.ruleForm.userScope === '1' || this.ruleForm.userScope === '2') {
          formData.userScopeList =
            this.ruleForm.userScope === '1'
              ? this.getSelectedUsers()
              : this.getSelectedUserGroups();
        } else if (this.ruleForm.userScope === '4') {
          formData.userScopeList = this.ruleForm.importedUserList;
        }
        // 编辑/copy
        if (this.editModel === 'edit') {
          formData.activityId = this.activityId;
        }
        return formData;
      },
      getSelectedUsers() {
        const selecteUsers = [];
        const users = this.$refs.treeUser.getCheckedNodes(true, false);
        users.forEach(item => {
          selecteUsers.push({
            dataId: item.id,
            dataName: item.name,
            orgType: item.orgType,
          });
        });
        return selecteUsers;
      },
      getSelectedUserGroups() {
        const selecteGroups = [];
        const groups = this.$refs.treeUserGroup.getCheckedNodes(true, false);
        groups.forEach(item => {
          selecteGroups.push({
            dataId: item.id,
            dataName: item.name,
          });
        });
        return selecteGroups;
      },

      handleUsersTree() {
        this.ruleForm.userList = this.$refs.treeUser.getCheckedKeys(true) || [];
        // console.log('this.ruleForm.stationList', checkedKeys, this.ruleForm.stationList);
      },
      handleUsersGroupTree() {
        this.ruleForm.userGroupList = this.$refs.treeUserGroup.getCheckedKeys(true) || [];
      },

      /**
       * 输入框数量校验
       */
      checkCouponNumber(rule, value, callback) {
        // 输入的所有数据
        const result = this.$refs.combination.dealResult();
        // 当前行index
        const rowIndex = rule.field.split('.')[1];
        // 选择的优惠券id
        const { couponId = '' } = result[rowIndex];

        if (!couponId) {
          return callback();
        }
        const [coupon] = this.coupons.filter(item => item.id === couponId);

        const validState = Number(value) > coupon.leftCount;

        return validState ? callback(`超过剩余数量${coupon.leftCount}张，请重新输入`) : callback();
      },
      /**
       * 活动名称校验
       */
      onlyCheckName(rule, value, callback) {
        if (value) {
          couponActivity
            .checkNameOnly({
              activityName: value,
              activityType: '3',
              activityId: this.editModel === 'edit' ? this.activityId : '',
            })
            .then(res => {
              if (res?.repeatFlag) {
                // 重复
                // 校验未通过
                this.nameValidError = '活动名称已存在'; // res.resultDesc;
                callback(new Error(this.nameValidError));
                // eslint-disable-next-line no-dupe-else-if
              } else if (res?.repeatFlag === false) {
                // 校验通过
                this.nameValidError = '';
                callback();
              }
            });
        }
      },
      /**
       * 日期校验
       */
      dateChange() {},
      isDateBeforeNow() {
        const date = this.ruleForm.sendTime;
        if (date) {
          const selectdate = Date.parse(new Date(`${date}:00:00`));
          // 当前时间戳
          const currentTime = Date.parse(new Date());
          // console.log(selectdate, currentTime);
          if (selectdate < currentTime) {
            this.ruleForm.sendTime = '';
            return true;
          }
        }
        return false;
      },
      handleTreeCheckChange(checkedKeys) {
        this.ruleForm.orgList = checkedKeys.length > 0 ? checkedKeys : '';
      },
      handleMode() {
        this.ruleForm.sendTime = '';
      },
      checkMessage(message) {
        if (message) {
          this.rules.userList[0].message = message;
          this.$refs.ruleForm.validateField('userList');
          return;
        }
        this.$refs.ruleForm.clearValidate('userList');
        this.rules.userList[0].message = '请上传文件';
      },
      /**
       * 批量导入
       */
      batchImport(file) {
        console.log(file);
        const formData = new FormData();
        const param = {
          file: file.raw,
          // paramJson: JSON.stringify({}), // 参会不一样
        };
        Object.entries(param).forEach(([k, v]) => {
          formData.append(k, v);
        });
        couponActivity
          .importUserList(formData)
          .then(res => {
            console.log('res---导入用户列表信息:\n', res);
            this.$message({
              message: '导入文件成功',
              type: 'success',
            });
            this.ruleForm.importedUserList = res?.importUserDTOS.map(item => {
              return {
                dataId: item.id,
                dataName: item.userAccount,
              };
            });
          })
          .catch(() => {
            this.$refs.file.fileList = [];
          });
      },
      /**
       * 获取文件
       */
      uploadFile(file, fileList, isCheck) {
        if (isCheck) {
          this.batchImport(file);
        }
      },
      methodChange() {
        this.ruleForm.orgList = [];
        this.ruleForm.userList = [];
      },
      handleRemove() {
        this.ruleForm.file = undefined;
      },
      /**
       * 重置数据
       */
      resetRuleForm() {
        this.ruleForm = {
          activityName: '',
          outName: '',
          sendType: '1', // 发放类型（1:立即发放，2:定时发放）
          sendTime: '', // 发送时间
          userScope: '1',
          orgList: [],
          userList: [],
        };
        this.rules.userList[0].message = '请上传文件';
      },

      /**
       * 取消操作
       */
      beforeCancel(drawerDone) {
        this.$dialog({
          dialogType: 'feedback',
          showTitle: false,
          closeOnClickModal: false,
          desc: '取消后操作将不被保存，确认是否取消？',
          onConfirm: done => {
            // 关闭弹框
            done();
            // 关闭抽屉
            drawerDone();
            this.resetRuleForm();
          },
        });
      },
      /**
       * 保存操作
       */
      async beforeDrawer() {
        const ruleForm = JSON.parse(JSON.stringify(this.ruleForm));
        console.log('ruleForm', ruleForm);
        const { data, validState } = await this.$refs.combination.getResult();
        this.$refs.ruleForm.validate(valid => {
          if (valid && validState) {
            ruleForm.couponList = this.resetdata(data) || [];
            console.log(ruleForm, this.ruleForm);
            this.$axios({
              url: 'URL_DISTRIBUTION_ACTIVITY_ADD',
              headers: {
                Operation: 'add',
              },
              data: {
                body: ruleForm,
              },
            })
              .then(res => {
                this.$emit('change', false);
                console.log('data', res);
                this.$emit('update-data');
                this.resetRuleForm();
                // 重新获取优惠券(优惠券数据变更了)
                this.getCouponAndFleet(true);
              })
              .catch(() => {});
          }
        });
      },
      handleChangeShow(v) {
        this.$emit('change', v);
        console.log('drawerVisible', this.drawerVisible);
      },
    },
  };
</script>
<style lang="scss">
  .has-time.ykc-date-picker,
  .has-time.hourHide {
    .el-picker-panel__footer .el-button.el-button--text {
      display: none;
    }
  }
</style>
