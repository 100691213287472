<template>
  <scroll-layout>
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @click="handleSearchConfirm"
        @clean="handleSearchClean"></ykc-search>
    </template>
    <div class="motorcade-main-account">
      <ykc-table
        ref="YkcTable"
        :data="tableData"
        :columns="tableColumns"
        :title="tableTitle"
        operateFixedType="right"
        :selectable="false"
        :selectOnIndeterminate="false"
        :operateButtons="tableOperateButtons">
        <template #headerButtons>
          <div class="table-header-button">
            <template v-for="(btn, i) in tableButtons">
              <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handler">
                {{ btn.label }}
              </ykc-button>
            </template>
          </div>
        </template>
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :current-page.sync="pageInfo.current"
          @current-change="handlePaginationCurrentChange"
          :page-size.sync="pageInfo.size"
          @size-change="handlePaginationSizeChange"></ykc-pagination>
      </ykc-table>
    </div>
    <ykc-drawer
      :show.sync="showDrawer"
      :title="drawerTitle"
      :on-close="onAddDrawerClose"
      :before-cancel="onAddDrawerCancel"
      showDrawer
      ensure-txt="保存">
      <template #footer>
        <div class="drawer-button-wrap">
          <template v-for="(btn, i) in drawerButtons">
            <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handler">
              {{ btn.label }}
            </ykc-button>
          </template>
        </div>
      </template>
      <div class="drawer-main-body" v-if="showDrawer">
        <AddOrEdit
          v-if="drawerType === 'edit' || drawerType === 'add'"
          :active="stepsIndex"
          ref="addOrEdit"
          :isEdit="isEdit"
          :siteName="siteName"
          :id="id"></AddOrEdit>
      </div>
    </ykc-drawer>
  </scroll-layout>
</template>

<script>
  import YkcDialog from '@/components/ykc-ui/dialog/index';
  import AddOrEdit from './AddOrEdit.vue';
  import { propertySiteData } from '@/service/apis';
  import { code } from '@/utils';

  export default {
    name: 'PropertySiteManage',
    components: {
      AddOrEdit,
    },
    data() {
      return {
        maxStepIndex: 1,
        selectIds: [],
        id: '',
        drawerType: '',
        willEndCount: 0,
        listType: '0',
        stepsIndex: 0,
        maxIndex: 2,
        drawerButtons: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => true,
            handler: () => {
              YkcDialog({
                dialogType: 'feedback',
                showTitle: false,
                showFooter: true,
                desc: '取消后操作将不被保存，确认是否取消？',
                onCancel: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                },
                onConfirm: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                  // this.$refs.addOrEdit.ruleForm.resetFields();
                  this.showDrawer = false; // 隐藏抽屉
                },
              });
            },
          },
          {
            label: '保存',
            enabled: () => {
              return true;
            },
            disabled: () => {
              return this.isRequest;
            },
            handler: () => {
              this.isRequest = true;
              this.$refs.addOrEdit
                .submitForm()
                .then(() => {
                  this.stepsIndex = 0;
                  this.showDrawer = false;
                  this.isRequest = false;
                  this.searchTableList();
                })
                .finally(() => {
                  this.isRequest = false;
                });
            },
          },
        ],
        tableButtons: [
          {
            label: '新增',
            type: 'plain',
            enabled: () => code('operation:config:propertySite:add'),
            handler: () => {
              this.add();
            },
          },
        ],

        tableData: [],

        tableColumns: [
          { label: '场地名称', prop: 'siteName', minWidth: '200px' },
          { label: '创建人', prop: 'createdBy', minWidth: '150px' },
          { label: '创建时间', prop: 'createdTime', minWidth: '200px' },
          { label: '修改人', prop: 'modifiedBy', minWidth: '150px' },
          { label: '修改时间', prop: 'modifiedTime', minWidth: '200px' },
        ],
        tableTitle: '场地列表',

        tableOperateButtons: [
          {
            render: (h, data, { row }) =>
              code('operation:config:propertySite:edit') ? (
                <ykc-button
                  type="text"
                  onClick={() => {
                    this.showAddDrawer = true;
                    this.isEdit = true;
                    this.id = row.id;
                    this.siteName = row.siteName;
                    this.drawerType = 'edit';
                    this.showDrawer = true;
                  }}>
                  编辑
                </ykc-button>
              ) : null,
          },
        ],
        pageInfo: {
          total: 0,
          current: 1,
          size: 10,
        },
        searchParams: {
          siteName: '',
        },
        showDrawer: false,
        isEdit: false,
        siteName: '', // 编辑的siteName
      };
    },
    created() {
      this.searchTableList();
    },
    computed: {
      drawerTitle() {
        return `场地${this.isEdit ? '编辑' : '新增'}`;
      },
      searchData() {
        return [
          {
            comName: 'YkcInput',
            key: 'siteName',
            label: '场地名称',
            placeholder: '请输入场地名称',
          },
        ];
      },
    },
    methods: {
      add() {
        this.maxStepIndex = 1;
        this.stepsIndex = 0;
        this.showAddDrawer = true;
        this.isEdit = false;
        this.id = '';
        this.siteName = '';
        this.drawerType = 'add';
        this.showDrawer = true;
      },
      /** 根据条件查询数据 */
      searchTableList() {
        Promise.all([
          propertySiteData.propertySiteList({
            ...this.pageInfo,
            ...this.searchParams,
          }),
        ]).then(([res1]) => {
          this.tableData = res1?.records;
          this.pageInfo.total = res1?.total || 0;
        });
      },
      onAddDrawerClose(done) {
        done();
      },
      /**
       * @desc 点击抽屉取消按钮 callback
       * */
      onAddDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: done => {
            done();
          },
          onConfirm: done => {
            done();
            drawerDone();
            if (this.isEdit) {
              this.isEdit = false;
            }
          },
        });
      },
      /**
       * @desc 点击查询按钮
       * */
      handleSearchConfirm(form) {
        this.pageInfo.current = 1;
        Object.assign(this.searchParams, form, {
          operatorType: '2',
        });
        this.searchTableList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
      },
      /**
       * @desc 分页 change 事件监听器
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.searchTableList();
      },
      /**
       * 分页 size  change  事件监听器
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.searchTableList();
      },
    },
  };
</script>
<style lang="scss" scoped>
  .link {
    color: blue;
    cursor: pointer;
  }
  .el-radio-group .item {
    border: 0;
  }
</style>
