<!-- 物业新增 -->
<template>
  <ykc-form class="el-form-wrap">
    <div>
      <ykc-form :model="ruleForm" :rules="rules" ref="ruleForm0" class="el-form-wrap">
        <ykc-form-item label="场地名称" prop="siteName">
          <ykc-input
            placeholder="请输入场地名称"
            maxlength="50"
            v-model="ruleForm.siteName"></ykc-input>
        </ykc-form-item>
      </ykc-form>
    </div>
  </ykc-form>
</template>
<script>
  import { propertySiteData } from '@/service/apis';

  export default {
    props: {
      active: {
        type: Number,
        default: 0,
      },
      isEdit: {
        type: Boolean,
        default: false,
      },
      id: {
        type: [Number, String],
      },
      siteName: {
        type: String,
        default: '',
      },
    },
    name: 'AddOrEdit',
    data() {
      return {
        ruleForm: {
          id: '',
          siteName: '',
        },
        rules: {
          siteName: [{ required: true, message: '请输入场地名称', trigger: 'blur' }],
        },
      };
    },
    mounted() {
      if (this.id) {
        this.ruleForm = {
          ...this.ruleForm,
          siteName: this.siteName,
        };
      } else {
        this.$refs.ruleForm0.resetFields();
      }
    },
    computed: {
      formData() {
        const formData = {
          ...this.ruleForm,
          id: this.id, // 编辑需要传
        };
        return formData;
      },
    },
    methods: {
      /** 表单提交事件，用于父组件定义的"完成"或"保存"按钮点击事件 */
      submitForm() {
        return new Promise(resolve => {
          this.validateAllForm().then(() => {
            if (!this.id) {
              propertySiteData
                .addSite(this.formData)
                .then(() => {
                  this.$message({
                    message: '保存成功',
                    type: 'success',
                  });
                  resolve();
                })
                .catch(() => {});
            } else {
              // console.log('编辑', this.formData);
              propertySiteData
                .editSite(this.formData)
                .then(() => {
                  this.$message({
                    message: '保存成功',
                    type: 'success',
                  });
                  resolve();
                })
                .catch(() => {});
            }
          });
        });
      },
      /** 校验当前表格是否通过 */
      validateForm() {
        return new Promise((resolve, reject) => {
          this.$refs[`ruleForm${this.active}`].validate(valid => {
            if (valid) {
              resolve();
            } else {
              reject();
            }
          });
        });
      },
      /** 校验所有表格是否通过 */
      validateAllForm() {
        return new Promise((resolve, reject) => {
          Promise.all(
            Object.keys(this.$refs)
              .filter(o => o.includes('ruleForm'))
              .map(o => {
                return new Promise((_resolve, _reject) => {
                  this.$refs[o].validate(valid => {
                    if (valid) {
                      _resolve();
                    } else {
                      _reject();
                    }
                  });
                });
              })
          )
            .then(() => {
              resolve();
            })
            .catch(() => {
              reject();
            });
        });
      },
    },
  };
</script>
<style lang="scss" scoped></style>
